import React, { lazy, startTransition, StrictMode, Suspense, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import MasterLayout from './layouts/master';

// Order of imports is important. The order should be as follows:
// import "./styles/vendor/normalize.scss";
// import "./styles/global.scss";
// import "./styles/utils.scss";
// import "./styles/webbook-content.scss";
// import "./styles/non-responsive.scss";
import "./styles/vendor/normalize.scss";
import "./styles/global.scss";
import "./styles/utils.scss";
import "./styles/webbook-content.scss";
import "./styles/non-responsive.scss";

import LoggedOut from './components/page/logged-out';
import ConfigContext from './contexts/config';
import MenuContext from './contexts/menu';
import PageDataContext from './contexts/pageData';
import SearchContext from './contexts/search';
import UserContext from './contexts/user';
import appInstallEventHandlerSetup from './events/appInstallEventHandlerSetup';
import Content from "./pages/content";
import routes from '../common/staticRoutes';
import StatusContext from './contexts/status';
import Heartbeat from './components/heartbeat';
import FatalErrorComponent from './pages/fatalError';
import GaTracking from './components/gaTracking';
import MasterLayoutContext from './contexts/masterLayout';
import contentTypes from './content-types';
import templateTypes from './components/page/templateTypes';
const Activities = lazy(() => import('./pages/activities'));
const Favorites = lazy(() => import('./pages/favorites'));

export default function App({ menu: initialMenu, user: initialUser, config, pageData }) {

  function pageIsFullWidth(data) {
    if (data?.type === contentTypes.PAGE && data?.value?.template === templateTypes.FRONTPAGE)
      return true;

    if (data?.type === contentTypes.PAPERSHOW)
      return true;

    return false;
  }

  function footerIsHidden(data) {
    if (data?.type === contentTypes.WEBBOOK)
      return true;

    if (data?.type === contentTypes.PAPERSHOW)
      return true;

    return false;
  }

  const [data, setPageData] = useState(pageData);
  const [user, setUserState] = useState(initialUser);
  const [menu, setMenuState] = useState(initialMenu);
  const [fullWidth, setFullWidthState] = useState(pageIsFullWidth(data));
  const [hideFooter, setHideFooterState] = useState(footerIsHidden(data));
  const [closeSearchOverlayTrigger, setCloseSearchOverlayTriggerState] = useState(false);

  // Used to keep track of page rendering for the webbook reader
  const [initialRender, setInitialRenderState] = useState(() => { return true });
  // Used to keep track of general server side rendering
  const [ssr, setSsr] = useState(() => true);
  const [searchValue, setSearchValueState] = useState(() => {
    if (pageData && pageData.bucket && pageData.bucket.query)
      return pageData.bucket.query;
    return "";
  });

  const [statusMessage, setStatusMessageState] = useState("");
  const [statusType, setStatusTypeState] = useState("success");

  const [showSearchBox, setShowSearchBoxState] = useState(() => {
    if (pageData?.value?.path === "/s")
      return true;
    return false;
  });
  const [hasFocus, setHasFocusState] = useState(false);
  const [isActive, setIsActiveState] = useState(false);

  const setUser = (input) => {
    startTransition(() => {
      setUserState(input);
    })
  }

  const setMenu = (input) => {
    startTransition(() => {
      setMenuState(input);
    })
  };

  const setInitialRender = (input) => {
    startTransition(() => {
      setInitialRenderState(input);
    })
  };

  const setSearchValue = (input) => {
    startTransition(() => {
      setSearchValueState(input);
    })
  };

  const setStatusMessage = (input) => {
    startTransition(() => {
      setStatusMessageState(input);
    })
  };

  const setStatusType = (input) => {
    startTransition(() => {
      setStatusTypeState(input);
    })
  };

  const setShowSearchBox = (input) => {
    startTransition(() => {
      setShowSearchBoxState(input);
    })
  };

  const setHasFocus = (input) => {
    startTransition(() => {
      setHasFocusState(input);
    })
  };

  const setIsActive = (input) => {
    startTransition(() => {
      setIsActiveState(input);
    })
  };

  const setData = (data) => {
    startTransition(() => {
      setPageData(data || {});
    });
  };

  const setFullWidth = (input) => {
    startTransition(() => {
      setFullWidthState(input);
    });
  };

  const setHideFooter = (input) => {
    startTransition(() => {
      setHideFooterState(input);
    });
  };

  useEffect(() => {
    startTransition(() => {
      setSsr(false);
    });
  }, []);

  const flipCloseSearchOverlayTrigger = () => {
    startTransition(() => {
      setCloseSearchOverlayTriggerState(!closeSearchOverlayTrigger);
    })
  };

  useEffect(() => {
    appInstallEventHandlerSetup(window, config.version);
  }, []);

  const masterLayout = (
    <MasterLayout />
  );

  return (
    <StrictMode>
      <PageDataContext.Provider value={{
        data
        , setData
        , initialRender
        , setInitialRender
        , ssr
        , setSsr
      }}>
        <UserContext.Provider value={{ user, setUser }}>
          <ConfigContext.Provider value={config}>
            <MenuContext.Provider value={{ menu, setMenu }}>
              <StatusContext.Provider value={{
                message: statusMessage,
                type: statusType,
                setStatus: (message, type) => {
                  setStatusMessage(message);
                  setStatusType(type);
                }
              }}>
                <SearchContext.Provider value={
                  { 
                    searchValue
                    , setSearchValue
                    , showSearchBox
                    , setShowSearchBox
                    , hasFocus
                    , setHasFocus
                    , isActive
                    , setIsActive 
                    , closeSearchOverlayTrigger
                    , flipCloseSearchOverlayTrigger
                  }} >
                  <MasterLayoutContext.Provider value={{ fullWidth, setFullWidth, hideFooter, setHideFooter }}>
                    <Routes>
                      <Route element={masterLayout} errorElement={<FatalErrorComponent />}>
                        {
                          !!config.flags.signup &&
                          <>
                            <Route path={routes.LOGGED_OUT} element={
                              <LoggedOut sitename={config.siteName} />
                            } />
                            <Route path={routes.ACTIVITIES} element={
                              <Suspense>
                                <Activities
                                  sitename={config.siteName}
                                  years={data.years}
                                  year={data.year}
                                  months={data.months}
                                  type={data.type}
                                  statusCode={data.statusCode}
                                />
                              </Suspense>
                            } />
                            <Route path={routes.FAVORITES} element={
                              <Suspense>
                                <Favorites
                                  sitename={config.siteName}
                                  favorites={data.favorites}
                                  totalAmount={data.total}
                                  type={data.type}
                                  statusCode={data.statusCode}
                                />
                              </Suspense>
                            } />
                          </>
                        }
                        <Route path={routes.CONTENT_PAGE} element={<Content />} errorElement={<FatalErrorComponent />} />
                      </Route>
                      <Route path={`${routes.PROFILE}/*`} element={<Reload />} />
                    </Routes>
                    <Heartbeat />
                    <GaTracking />
                  </MasterLayoutContext.Provider>
                </SearchContext.Provider>
              </StatusContext.Provider>
            </MenuContext.Provider>
          </ConfigContext.Provider>
        </UserContext.Provider>
      </PageDataContext.Provider>
    </StrictMode>
  );
}

/**
 * Reload page component.
 * When a route is CSR, but should be SSR. Can happen when "going back" in history.
 * Example: 
 *  /profile/login route can be requested using history, this should always be SSR.
 */
function Reload() {
  useEffect(() => {
    window.location.reload();
  }, []);

  return null;
};