import { useState, useEffect } from 'react';


/**
 *  Wrapper that passes knowledge of mouse movements to component. 
 *  Used on Menuitems for delaying opening dropdown menus.
 *  
 *  Starts tracking movements when mouse is over element, stops when mouse leaves element.
 *  Passes object as props: 
 *  {
 *      isOver: bool - is mouse over ui element
 *      isMoving: bool - is mouse moving or has it stopped Only 
 *      onMouseOver: func - init function for tracker. must be assigned to an elements onMouseOver property
 *  }
 */
const withMouseTracking = (UI, delay = 50) => {
    function MouseTracker(props) {
        const [isMoving, setIsMoving] = useState(true);
        const [isOver, setIsOver] = useState(false);
        const [target, setTarget] = useState(null);
        const [currentTarget, setCurrentTarget] = useState(null);
        let timer = null;

        useEffect(() => {
            return () => { clearTimeout(timer) }
        }, [])
        function UIMouseOver(e) {
            setIsOver(true);
            setTarget(e.target);
            setCurrentTarget(e.currentTarget);
            document.addEventListener('mousemove', UIMouseMove);
            e.target.addEventListener('mouseout', UIMouseOut);
        }

        function UIMouseOut(e) {
            setIsOver(false);
            setIsMoving(true);
            clearTimeout(timer);
            document.removeEventListener('mousemove', UIMouseMove);
            e.target.removeEventListener('mouseout', UIMouseOut);
        }

        function UIMouseMove(e) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                setIsMoving(false);
            }, delay);
        }


        return (
            <UI
                {...props}
                mouseTracking={{
                    isOver
                    , isMoving
                    , eventTarget: target
                    , eventCurrentTarget: currentTarget
                    , onMouseOver: UIMouseOver
                    , setTarget
                    , setCurrentTarget
                }}
            />
        )
    }

    return MouseTracker
};

export default withMouseTracking;