import styles from '../../styles/components/header/header.module.scss'
import Logo from './logo'
import Menu from './menu'
import classNames from '../../utils/classNames'
import HeaderSearch from './header-search';
import { useLocation } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import SearchContext from '../../contexts/search';
import ConfigContext from '../../contexts/config';
import NotificationBar from '../notification/notification-bar';

export default function Header({
  container = false
  , isErrorPage
}) {
  const location = useLocation();
  const { flags } = useContext(ConfigContext);
  const { frontpageHeaderSearch } = flags;
  const {
    searchValue: initialQuery,
    setShowSearchBox,
    showSearchBox,
    isActive: searchActive,
    setIsActive: setSearchActive,
    hasFocus: searchHasFocus,
    closeSearchOverlayTrigger
  } = useContext(SearchContext);
  const [activeOverlay, setActiveOverlay] = useState('');
  const [mobileBurgerActive, setMobileBurgerActive] = useState(false);

  useEffect(() => {
    let isSearchPage;
    if ((location.pathname === '/s') && !isErrorPage)
      isSearchPage = true;
    else
      isSearchPage = false;
    if (!isSearchPage) {
      setMobileBurgerActive(false);
      setSearchActive(false);
      setShowSearchBox(false);
    } else {
      setSearchActive(false);
      setShowSearchBox(true);
    }
    setActiveOverlay('')
  }, [closeSearchOverlayTrigger]);

  useEffect(() => {
    if (searchActive) setActiveOverlay('search');
  }, [searchActive])

  useEffect(() => {
    if (mobileBurgerActive) {
      setActiveOverlay('menu');
      let isSearchPage;
      if ((location.pathname === '/s') && !isErrorPage)
        isSearchPage = true;
      else
        isSearchPage = false;
      if (isSearchPage) setShowSearchBox(false);
    }
  }, [mobileBurgerActive])

  useEffect(() => {
      if (activeOverlay === 'menu') {
        setSearchActive(false);
        setMobileBurgerActive(true);
      }
      else setMobileBurgerActive(false)
  }, [activeOverlay])

  useEffect(() => {
      const element = document.querySelector("body");
      const viewWidth = element.clientWidth;
      const isMobile = viewWidth < Number.parseFloat(`${styles.containerMedium}`.replace("px", ""));
      if (!mobileBurgerActive && !searchActive) {
        element.classList.remove("no-scroll-body");
      } else {
        // When search overlay is open we only remove scroll if device is considered mobile
        if (searchActive && !mobileBurgerActive && !isMobile) return;
        if (!element.classList.contains("no-scroll-body")) {
          element.classList.add("no-scroll-body");
        }
      }
  }, [mobileBurgerActive, searchActive]);

  function setMobileBurgerMenuState() {
    if (activeOverlay !== 'menu') setActiveOverlay('menu');
    else setActiveOverlay('');
  }

  function showHeaderSearch() {
    let isFrontpage;
    if (location.pathname === '/' || location.pathname === '')
      isFrontpage = true;
    else
      isFrontpage = false;
    if (frontpageHeaderSearch)
      return true;
    if (isFrontpage)
      return false;
    else
      return true;
  }

  const classes = classNames({
    [styles.headerFixedTop]: true,
    "header-top-fixed": true,
    "container": container,
    [styles.focus]: mobileBurgerActive || searchActive || searchHasFocus,
    [styles.active]: (showSearchBox && !searchHasFocus)
  });

  return (
    <>
      <div className={classes}>
        <div className={styles.headerTop}>
          <Logo />
          <Menu
            mobileBurgerMenuState={mobileBurgerActive}
            setMobileBurgerMenuState={setMobileBurgerMenuState}
          />
          {
            showHeaderSearch() &&
            <HeaderSearch
              setActiveOverlay={setActiveOverlay}
            />
          }
        </div>
      </div>
      <NotificationBar />
    </>
  )
}