import classNames from "../../utils/classNames";
import styles from "../../styles/components/header/search-input.module.scss";
import { useTranslation } from "react-i18next";
import Search from "../search/search";
import routes from "../../../common/staticRoutes";
import { useContext } from "react";
import UserContext from "../../contexts/user";

const containerClassName = "container"

export default function SearchInputOverlay({ onClose, onSubmit }) {

  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  let showNarrowSearchOverlay = true;
  if (typeof window !== 'undefined') {
    const header = document.querySelector(`div[class^="_headerFixedTop"]`);
    if (header && header.classList.length) {
      if (!header.classList.contains(containerClassName)) {
        showNarrowSearchOverlay = false;
      }
    }
  }

  const contentContainer = classNames({
    "content": true,
    [containerClassName]: showNarrowSearchOverlay
  });

  const backdrop = classNames({
    "backdrop": true,
    [containerClassName]: showNarrowSearchOverlay
  });

  let backdropHeight = null;
  const element = document.querySelector("body");
  const viewWidth = element.clientWidth;
  const isMobile = viewWidth < Number.parseFloat(`${styles.containerMedium}`.replace("px", ""));
  if (isMobile) {
    const bodyHeight = typeof document.body !== "undefined" 
      ? document.body.getBoundingClientRect().height
      : null;
    
    const heightOfHeaderAndSearchInput = 60 + 80.9;
  
    backdropHeight = bodyHeight ? `${bodyHeight - heightOfHeaderAndSearchInput}px` : null;
  }

  return (
    <>
      <div className={styles.overlayOuter}>
        <div id="headerSearchOverlayDiv" className={"overlay"}>
          <div className={contentContainer}>
            <div className={styles.headerSearchInput}>
              <Search
                isAuthenticated={user.authenticated}
                onClose={onClose}
                onSubmit={onSubmit}
                placeholder={t("search.input_placeholder")}
                searchUri={routes.SEARCH}
                isInOverlay={true}
              />
            </div>
          </div>
          <div 
            style={{ height: backdropHeight }} 
            className={backdrop}
          >
          </div>
        </div>
      </div>
    </>
  );
}
