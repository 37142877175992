import classNames from '../../utils/classNames';
import MenuMobileOverlay from './menu-mobile-overlay';
import styles from "../../styles/components/header/menu.module.scss";
import XIcon from '../icons/x';
import { createPortal } from "react-dom";
import IconHOC from '../icons/hoc';
import { useRef } from 'react';


export default function MenuMobile({active, setActive}) {
  const classes = classNames({
    [styles.icon]: true,
    [styles.active]: active
  });
  const menuRef = useRef();

  /**
   * @param {Event} e 
   */
  function onMobileOverlayToggle(e) {
    if (!active) {
      if (menuRef.current?.scrollIntoViewIfNeeded) {
        window.requestAnimationFrame(() => {
          menuRef.current.scrollIntoViewIfNeeded();
        })
      }
    }
    setActive(!active);
  }

  function menuIcon() {
    const X = IconHOC(XIcon, "0 0 12.73 12.73");
    if (active) {
      return (
        <div className={styles.menuIconContainer}>
          <X width="18px" height="18px" />
        </div>
      );
    }

    return (
      <div className={styles.menuIconContainer}>
        <div className={styles.menuIconLine} />
        <div className={styles.menuIconLine} />
        <div className={styles.menuIconLine} />
      </div>
    );
  }

  return (
    <>
      <div
        className={classes}
        onClick={onMobileOverlayToggle}
        key={"mobileOverlayToggleKey"}
        ref={menuRef}
      >
        {menuIcon()}
      </div>
      {active &&
        createPortal(
          <div className="overlay">
            <div className="content full-height">
              <MenuMobileOverlay />
            </div>
          </div>,
          document.getElementsByTagName("body")[0]
        )
      }
    </>
  );
}
