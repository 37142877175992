import XIcon from "../icons/x";
import styles from "../../styles/components/notification/notificationBar.module.scss";
import IconHOC from "../icons/hoc";
import { startTransition, useContext, useEffect, useRef } from "react";
import StatusContext from "../../contexts/status";
import { useLocation, useNavigate } from "react-router-dom";
import { isExternalLink } from "../../../common/utils/url";


export default function NotificationBar() {
  const {
    message,
    type,
    setStatus
  } = useContext(StatusContext);

  const messageRef = useRef();

  const location = useLocation();
  const navigate = useNavigate();

  const X = IconHOC(XIcon, "0 0 12.73 12.73");

  useEffect(() => {
    startTransition(() => {
      if (message) {
        showStatusbar();
      } else {
        hideStatusbar();
      }
    })
  }, [message]);

  useEffect(() => {
    if (message) {
      const messageDiv = messageRef.current;
      if (!messageDiv) return;
      const linksInMessageDiv = messageDiv?.querySelectorAll(`a`);
      linksInMessageDiv.forEach(
        /**
         * @param {HTMLElement} link 
         */
        (link) => {
        if (!isExternalLink(link.getAttribute("href"))) {
          link.onclick = (e) => {
            e.preventDefault();
            navigate(link.getAttribute("href"));
          }
        }
      });
    }
  }, [message]);

  useEffect(() => {
    setTimeout(()=> {
      if (message) {
        try {
          const body = document.body;
          const notificationContent = document.querySelector(`div[class^="_notification_"]`);
          const height = notificationContent.getClientRects()[0].height;
          if (!isMobile()) {
            body.style = [`margin-top: ${60 + height}px;`];
          }
        } catch (err) {
        }
      }
    }, 1);
  }, [message]);

  useEffect(() => {
    startTransition(() => {
      setStatus("", "");
    })
  }, [location]);

  function isMobile() {
    const element = document.querySelector("body");
    const viewWidth = element.clientWidth;
    const isMobile = viewWidth < Number.parseFloat(`${styles.containerMedium}`.replace("px", ""));
    return isMobile;
  }

  function showStatusbar() {
    const notificationBar = document.querySelector("#notificationBar");
    notificationBar.classList.remove(styles.hidden);
  }

  function hideStatusbar() {
    const body = document.body;
    body.style = null;
    const notificationBar = document.querySelector("#notificationBar");
    notificationBar.classList.add(styles.hidden);
    setStatus("", "");
  }

  return (
    <div id="notificationBar" className={`${styles.notificationBar} ${styles.hidden}`}>
      <div className={`${styles.notification} ${styles[type] || ""}`}>
        <div className={styles.notificationInner}>
          <div ref={messageRef} dangerouslySetInnerHTML={{
            __html: message
          }} />
          <button 
            className={styles.notificationButtonClose}
            onClick={hideStatusbar}
          >
            <X height="18px" width="18px" />
          </button>
        </div>
      </div>
    </div>
  )
}