import { createContext } from "react";

const SearchContext = createContext({
  searchValue: ""
  , setSearchValue: (searchValue) => { }
  , showSearchBox: false
  , setShowSearchBox: (showSearchBox) => { }
  , hasFocus: false
  , setHasFocus: (hasFocus) => { }
  , isActive: false
  , setIsActive: (isActive) => { }
  , closeSearchOverlayTrigger: false
  , flipCloseSearchOverlayTrigger: () => {}
})
export default SearchContext;