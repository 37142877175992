import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ConfigContext from "../../contexts/config";
import { TRACKING_DELAY } from "../../../common/constants/constants";

let timeout = null;

export default function GaTracking() {
  const { pathname, search } = useLocation();
  const { enableTracking, googleAnalyticsId } = useContext(ConfigContext);

  useEffect(() => {
    if (!enableTracking)
      return;

    if (timeout)
      clearTimeout(timeout);
    timeout = setTimeout(() => {
      track(googleAnalyticsId);
    }, TRACKING_DELAY);

  }, [pathname, search]);

  return <></>;
}