import { useState, useEffect, useRef } from 'react';
import Debounce from 'lodash.debounce';
import withMouseTracking from '../../utils/mouse-tracker';
import styles from "../../styles/components/header/user-menu-dropdown.module.scss";
import { Link } from 'react-router-dom';
import { isExternalLink } from '../../../common/utils/url';
import reportAndThrowError from '../../errors/errorHandle';

export function MenuItemDropdown({ item, mouseTracking }) {
  const { isOver, isMoving, onMouseOver } = mouseTracking;
  const dropdownRef = useRef();
  const [hidden, setHidden] = useState(true);
  let debouncedMouseLeave = Debounce(hideDropdown, 50);

  useEffect(() => {
    if (isOver && !isMoving) {
      showDropdown();
    }
  }, [mouseTracking.isOver, mouseTracking.isMoving]);

  if (!item) reportAndThrowError(new Error("item must not be null"));
  if (!item.children || !item.children[0]) reportAndThrowError(new Error("item prop must contain child-array with at least one child"))

  function showDropdown() {
    setHidden(false);
    document.addEventListener('mousemove', debouncedMouseLeave)
  }

  function hideDropdown(event) {
    if (dropdownRef && dropdownRef.current?.contains(event.target)) {
      return;
    }
    setHidden(true);
    document.removeEventListener('mousemove', debouncedMouseLeave);
  }

  const isExternal = isExternalLink(item.uri);

  return (
    <div className={`${styles.dropdown} ${hidden ? styles.hidden : ""}`} ref={dropdownRef}>
      <div
        className={styles.target}
        onMouseOver={onMouseOver}
      >
        {
          isExternal
            ? <a href={item.uri} title={item.tooltip}>{item.title}</a>
            : <Link to={item.uri} title={item.tooltip}>{item.title}</Link>
        }
      </div>
      <div
        className={styles.menu}
      >
        <ul>
          {
            item.children.map((child, i) => {
              const isExternal = isExternalLink(child.uri);
              return (
                <li key={`dd${i}`}>
                  {
                    isExternal
                      ? <a href={child.uri} title={child.tooltip}>{child.title}</a>
                      : <Link to={child.uri} title={child.tooltip}>{child.title}</Link>
                  }
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

export default withMouseTracking(MenuItemDropdown)