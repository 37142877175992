import { CLIENT_LOGGING_RATE_LIMIT_COUNT_KEY, CLIENT_LOGGING_RATE_LIMIT_DATE_KEY } from '../../common/constants/constants';
import Fetcher from '../utils/fetcher';

const rateLimit = 10;
const rateLimitTimeUnit = 60 * 1000; // 1 minute

/**
 * 
 * @param {String} type 
 * @param {Object} log 
 */
export default async function logClientError(error) {
  function updateLogCountAndCheckIfShouldLog() {
    let shouldLog = true;

    try {
      const now = new Date().getTime();
      let latestLogTime = localStorage.getItem(
        CLIENT_LOGGING_RATE_LIMIT_DATE_KEY
      );
      if (!latestLogTime) {
        localStorage.setItem(CLIENT_LOGGING_RATE_LIMIT_DATE_KEY, now);
        latestLogTime = now;
      }
      let logCount = localStorage.getItem(CLIENT_LOGGING_RATE_LIMIT_COUNT_KEY)
        ? parseInt(localStorage.getItem(CLIENT_LOGGING_RATE_LIMIT_COUNT_KEY))
        : 1;

      if (!logCount)
        localStorage.setItem(CLIENT_LOGGING_RATE_LIMIT_COUNT_KEY, 1);

      const timeIsPassed =
        latestLogTime && now - parseInt(latestLogTime) > rateLimitTimeUnit
          ? true
          : false;

      if (!timeIsPassed && logCount >= rateLimit) shouldLog = false;

      if (timeIsPassed) {
        localStorage.setItem(CLIENT_LOGGING_RATE_LIMIT_COUNT_KEY, 1);
        localStorage.setItem(CLIENT_LOGGING_RATE_LIMIT_DATE_KEY, now);
      } else if (shouldLog) {
        localStorage.setItem(CLIENT_LOGGING_RATE_LIMIT_COUNT_KEY, logCount + 1);
      }
      return shouldLog;
    } catch (err) {
      return false;
    }
  }
  const shouldLog = updateLogCountAndCheckIfShouldLog();
  if (shouldLog) {
    const fetcher = new Fetcher();
    await fetcher.fetch('/api/error', "POST", error);
  }
}



/*
    // jwe: Var tidligere sat op på denne måde i app.jsx:

    window.onerror =  (message, source, lineno, colno, error) => {
      let errorData = {}
        
      if(error){
        errorData = {
          message : error.message,
          stack: error.stack
        }
      } else {
        errorData = {
          message : message,
          stack: source+':'+lineno
        }
      }
      logClientError(errorData);
      return true;
    }

*/