import { Suspense, lazy, useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MenuContext from '../../contexts/menu';
import styles from '../../styles/components/header/menu.module.scss';
import { isExternalLink } from '../../../common/utils/url';
import withMouseTracking from '../../utils/mouse-tracker';
import useMediaSize, { breakpoints } from '../../utils/mediaSize';
import { createPortal } from 'react-dom';
import Debounce from 'lodash.debounce';
const MegaDropdown = lazy(() => import('./megadropdown'));
import { ChevronDown } from '../icons';
import Fetcher from '../../utils/fetcher';
import DropdownContext from '../../contexts/megaDropdown';
import { MEGA_DROPDOWN_STATE_KEY } from '../../../common/constants/constants';

const megaDropdownResetData = { title: "", items: [] };
/**
 * @type {Fetcher}
 */
let fetcher;

/**
 * @typedef {{isOver: boolean, isMoving: boolean, eventTarget: HTMLElement, eventCurrentTarget: HTMLElement, onMouseover: Function}} mouseTracking
 * @param {{mouseTracking: mouseTracking}} props
 */
function MenuDesktop({ mouseTracking }) {
  const { menu } = useContext(MenuContext);
  const { dropdownData: cachedData, setDropdownData: setCachedData } = useContext(DropdownContext);
  const mediaSize = useMediaSize();
  const { isOver, isMoving, eventTarget, eventCurrentTarget, onMouseOver, setTarget, setCurrentTarget } = mouseTracking;

  const { pathname } = useLocation();
  const [megaDropdownData, setMegaDropdownData] = useState(megaDropdownResetData);
  let debouncedMouseLeave = Debounce(onMouseLeaveMega, 200);

  useEffect(() => {
    const menuElement = document.querySelector(`nav ul li a[href="${pathname}"]`);
    setTarget(menuElement?.parentElement);
    setCurrentTarget(menuElement?.parentElement);
    menuElement?.parentElement.addEventListener("mouseleave", enableMegaDropdown);
  }, [pathname]);

  /**
   * @param {Event} e 
   */
  function enableMegaDropdown(e) {
    if (getAnchorTagFromTarget(e.target)) {
      window.sessionStorage.setItem(MEGA_DROPDOWN_STATE_KEY, false);
      e.target.removeEventListener("mouseleave", enableMegaDropdown);
    }
  }

  useEffect(() => {
    if (isOver && !isMoving) {
      const megaDropdownIsDisabled = window.sessionStorage.getItem(MEGA_DROPDOWN_STATE_KEY);
      if (megaDropdownIsDisabled === "true") {
        document.addEventListener("mousemove", debouncedMouseLeave);
        return;
      }
      if (mediaSize == breakpoints.MOBILE || !mediaSize) return;
      const aTag = getAnchorTagFromTarget(eventCurrentTarget);
      let targetHref = aTag?.getAttribute("href");
      if (!targetHref) return;

      (async () => {
        try {
          if (!fetcher) {
            fetcher = new Fetcher();
          }
          /**
           * @typedef {{title: String, uri: String, tooltip: String, active: boolean, children: Array<menuItem>}} menuItem
           * @type {{type: String, value: Array<menuItem>} | null}
           */
          let dropdownData;
          if (cachedData[targetHref]) {
            dropdownData = cachedData[targetHref];
          } else {
            dropdownData =
              await fetcher.fetch("api/content" + targetHref + "?view=menu")
                .then(res => res.json());
            if (!dropdownData
              || !Array.isArray(dropdownData.value)
              || !dropdownData.value.length
            ) {
              // No dropdown data available
              return;
            }
            cachedData[targetHref] = dropdownData;
            setCachedData(cachedData);
          }
          setMegaDropdownData({ title: aTag.innerText, items: dropdownData.value });
          document.addEventListener("mousemove", debouncedMouseLeave);
        } catch (err) {
        }
      })();
    }
  }, [mouseTracking.isOver, mouseTracking.isMoving]);

  /**
   *
   * @param {HTMLElement} target
   */
  function getAnchorTagFromTarget(target) {
    if (!target) return null;
    if (target.nodeName === "A") {
      return target;
    }
    let a;
    // User might have pointed at the li-tag surrounding the a-tag
    a = target.querySelector(`a`);

    const maxLoopCount = 5;

    /**
     * 
     * @param {HTMLElement} element
     * @param {Number} loopCount
     * @returns {HTMLElement|null}
     */
    function getParentSVG(element, loopCount) {
      if (!element) return null;
      if (loopCount > maxLoopCount) return null;
      if (element.nodeName.toLowerCase() === "svg") {
        return element;
      }
      return getParentSVG(element.parentElement, loopCount + 1)
    }

    // User might have pointed at SVG inside the link
    if (a == null) {
      a = getParentSVG(target, 1)?.parentElement;
    }
    return a;
  }

  /**
   * @param {Event} e
   */
  function onMouseLeaveMega(e) {
    const megaDropdown = document.querySelector(`div[class^="_megaDropdown"]`);
    if (megaDropdown?.contains(e.target)) return;
    const link = eventCurrentTarget.querySelector("a");
    const eventLink = getAnchorTagFromTarget(e.target);
    if (link && eventLink && link.isEqualNode(eventLink)) return;
    setMegaDropdownData(megaDropdownResetData);
    window.sessionStorage.setItem(MEGA_DROPDOWN_STATE_KEY, false);
    document.removeEventListener("mousemove", debouncedMouseLeave)
  }

  function onLinkClickedInMegaDropdown() {
    setMegaDropdownData(megaDropdownResetData);
    document.removeEventListener("mousemove", debouncedMouseLeave)
  }

  function clearDropdownAndDisableItOnce() {
    setMegaDropdownData(megaDropdownResetData);
    document.addEventListener("mousemove", debouncedMouseLeave);
    window.sessionStorage.setItem(MEGA_DROPDOWN_STATE_KEY, true);
  }

  return (
    <>
      <div className={styles.nav}>
        <nav id="desktop-topmenu" className={styles.tabs}>
          <ul>
            {
              menu.topMenu.map((item, i) => {
                const props = {
                  title: item.tooltip
                  , id: `header-menu-link-${i}`
                }

                const isExternal = isExternalLink(item.uri);
                let link;
                if (!item.dropdown) {
                  isExternal
                    ? link = <a href={item.uri} {...props}
                    >{item.title}</a>
                    : link = <Link to={item.uri} {...props}>{item.title}</Link>
                } else {
                  isExternal
                    ? link = <a href={item.uri} {...props}
                      onMouseOver={onMouseOver}
                      onClick={clearDropdownAndDisableItOnce}
                    >{item.title}<ChevronDown width="13px" height="13px" />
                    </a>
                    : link = <Link to={item.uri} {...props}
                      onMouseOver={onMouseOver}
                      onClick={clearDropdownAndDisableItOnce}
                    >{item.title}<ChevronDown width="13px" height="13px" />
                    </Link>
                }

                return (
                  <li
                    key={`${i}`}
                    className={megaDropdownData.title === item.title ? styles.active : ""}
                    onMouseOver={item.dropdown
                      ? onMouseOver
                      : () => { }
                    }
                  >
                    {link}
                  </li>
                );
              })
            }
          </ul>
        </nav>
      </div>
      {
        !!megaDropdownData.items.length &&
        createPortal(
          <Suspense>
            <MegaDropdown
              title={megaDropdownData.title}
              items={megaDropdownData.items}
              onClick={onLinkClickedInMegaDropdown}
            />
          </Suspense>,
          document.body
        )
      }
    </>
  );
}

export default withMouseTracking(MenuDesktop, 300);