import { APP_INSTALLED } from "../../common/constants/constants";
import logClientEvent from "./logClientEvent";

/**
 * 
 * @param {Window} windowObject
 * @param {String} appVersion
 */
export default function appInstallEventHandlerSetup(windowObject, appVersion) {
  windowObject.addEventListener('appinstalled', () => {
    logClientEvent(APP_INSTALLED, { appVersion })
  });
}