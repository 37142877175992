import { useContext } from 'react';
import { Link } from 'react-router-dom';
import MenuContext from '../../contexts/menu';
import styles from '../../styles/components/footer/footer.module.scss';
import { isExternalLink } from '../../../common/utils/url';
import { useTranslation } from 'react-i18next';
import classNames from '../../utils/classNames';

export default function FooterMenu({ narrow = false }) {

  const { menu } = useContext(MenuContext);
  const { t } = useTranslation();

  function createColumn(columnData, columnNumber) {
    if (!columnData)
      return null;

    return columnData.map((category, categoryI) => {
      return <div className={styles.category} key={`category-${columnNumber}-${categoryI}`}>
        <p className={styles.heading}>{category.header}</p>
        <ul>
          {category.items.map((item, itemI) => {
            const props = { title: item.tooltip };
            const isExternal = isExternalLink(item.uri);
            return (
              <li key={`item-${columnNumber}-${categoryI}-${itemI}`}>
                {!!isExternal && <a href={item.uri} {...props}>{item.title}</a>}
                {!isExternal && <Link to={item.uri} {...props}>{item.title}</Link>}
              </li>
            )
          })}
        </ul>
      </div>
    });
  }

  const data = menu.footerMenu;
  const column1 = createColumn(data.column1, 1);
  const column2 = createColumn(data.column2, 2);
  const bannerContent = t('footer.banners_content');
  const categoryClasses = classNames({
    [styles.categories]: true
    , [styles.narrow]: narrow
  })

  return (
    <nav className={styles.footerNavigation}>
      <div className={categoryClasses}>
        <div className={styles.column} key={`column-1`}>
          {column1}
        </div>
        <div className={styles.column} key={`column-2`}>
          {column2}
          <FooterMenuBanners content={bannerContent} />
        </div>
      </div>
    </nav>
  )
}

// Only exported for testing purposes
export function FooterMenuBanners({ content }) {
  if (!content)
    return null;

  const classes = [styles.category, styles.grow, styles.banners];

  return <div
    className={classes.join(" ")}
    data-testid="footer-banners"
    dangerouslySetInnerHTML={{
      __html: content
    }}
  />;
}