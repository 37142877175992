import { useRouteError } from "react-router-dom";
import Error from "./error";
import logClientError from "../errors/consoleErrors";
import { useEffect, useState } from "react";
import ReloadingErrorPage from "./reloadingErrorPage";
import { logLevels } from "../../common/logLevels";

const reloadCooldown = 1 * 60 * 1000; // 1 minute
const lastRefreshTimeStampKey = "lastRefreshTimeStamp";

// Must be lower case values
const warningLogs = [
  "failed to fetch dynamically imported module",
  "importing a module script failed."
];

export default function FatalErrorComponent() {
  const [errorContent, setErrorContent] = useState(() => {
    try {
      // We attempt to access localStorage which doesn't exist in Node - hence the try-catch
      const lastRefresh = localStorage.getItem(lastRefreshTimeStampKey);
      const now = new Date().getTime();
      const isTimePassed = lastRefresh && ((now - parseInt(lastRefresh)) > reloadCooldown) ? true : false;
      
      // Based on reload cooldown we decide which error page to show as default
      // to avoid any "blinking" (switch from one error page to another)
      if (!lastRefresh || isTimePassed) {
        return <ReloadingErrorPage />;
      } else {
        return <Error />;
      }
    } catch (error) {
      logClientError({
        message: error.message,
        stack: error.stack
      });
      return <Error />;
    }
  });
  
  const error = useRouteError();

  useEffect(() => {
    async function refreshPageIfAllowed() {
      const lastRefresh = localStorage.getItem(lastRefreshTimeStampKey);
      const now = new Date().getTime();
      const isTimePassed = lastRefresh && ((now - parseInt(lastRefresh)) > reloadCooldown) ? true : false;
      
      if (!lastRefresh || isTimePassed) {
        localStorage.setItem(lastRefreshTimeStampKey, now.toString());
        let logLevel = null;

        const logIsWarning = warningLogs.some((logMessagePart) => {
          if (error.message?.toLowerCase().includes(logMessagePart)) return true;
          return false;
        });

        if (logIsWarning) {
          logLevel = logLevels.warning;
        }
        await logClientError({
          message: error.message,
          stack: error.stack,
          logLevel
        });
        location.reload();
      } else {
        setErrorContent(<Error />);
        logClientError({
          message: error.message + " - regular error page shown to user.",
          stack: error.stack
        });
      }
    }
    refreshPageIfAllowed();
  }, []);

  return errorContent;
}