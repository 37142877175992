import { createContext } from "react";

const ConfigContext = createContext({
  // This data is replaced in clientGetHandler with real config data when the app starts
  maxFreeWebbookPageReads: 5,
  language: "en-US",
  timeZone: "Europe/Copenhagen",
  siteId: 13,
  siteName: "",
  siteUrl: "",
  version: 1,
  flags: {
    education: true,
    reviews: true,
    signup: true,
    frontpageHeaderSearch: true,
    download: true,
    papers: true,
    limitFreeWebbookPageReads: true
  },
  ignoredPathPrefixes: [
    ""
  ]
})
export default ConfigContext;