import { Link } from "react-router-dom";
import routes from "../../../common/staticRoutes";
import styles from "../../styles/components/page/auth.module.scss";
import gridStyles from "../../styles/old/grid.module.scss";
import { useTranslation } from 'react-i18next';
import classNames from "../../utils/classNames";
import { ArrowRight } from "../icons";
import HeadTags from "../../utils/headtag-helper";
import { useScrollRestoringRenderWatcher } from "../../utils/renderWatcher";

export default function LoggedOut({sitename}) {
  const { t } = useTranslation();

  const pageClasses = classNames({
    [styles.authFormPage]: true,
    [styles.signOutForm]: true
  });

  const gridClasses = classNames({
    [gridStyles.gVertical]: true,
    [gridStyles.gCenter]: true
  });

  const title = t("account.logged_out_title");

  useScrollRestoringRenderWatcher(title);

  return (
    <>
      <HeadTags>
        <title>{`${title} - ${sitename}`}</title>
        <meta name="robots" content="noindex, follow" />
      </HeadTags>
      <div className={pageClasses}>
        <div className={styles.authFormWrapper}>
          <div className={styles.authForm}>
            <div className={gridClasses}>
              <h2 className={styles.authFormTitle}>
                {title}
              </h2>
              <p>
                <Link to={routes.FRONT_PAGE}>
                  {t("account.go_to_frontpage")}
                  <ArrowRight style={{ marginLeft: "8px" }} width="10px" height="10px" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}