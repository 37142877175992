import React from 'react';

const IconHOC = (Composed, viewBox = "0 0 10 10") => {
     class hoc extends React.PureComponent {
        render() {
            const { width, height  } = this.props;
            const className = "svg-icon " + (this.props.className || "");

            return (
                <svg width={width||'32px'} height={height||'32px'} viewBox={viewBox} className={className}>
                    <Composed {...this.props.svgConfig}/>
                </svg>
            );
        }
    }

    return hoc;
};


export default IconHOC;