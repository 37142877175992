import React, { Suspense, lazy } from 'react';
import ClassNames from '../../utils/classNames';
import SearchInput from './input';
import SearchProposalHOC from './searchProposals';
const ReadingList = lazy(() => import('../readingList/reading-list'));
import styles from "../../styles/components/header/search-input.module.scss";

class SearchComponent extends React.PureComponent {
  constructor(props) {
    super();
    this.state = { value: props.initialQuery || '', focusIndex: 0, showSearchProposals: true, isAuthenticated: props.isAuthenticated };
    this.onChange = onChange.bind(this);
    this.onSubmit = onSubmit.bind(this);
    this.setValueAndSubmit = setValueAndSubmit.bind(this);
    this.onFocus = onFocus.bind(this);
    this.onBlur = onBlur.bind(this);
    this.updateListIndex = updateListIndex.bind(this);
    this.closeKeyListener = closeKeyListener.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.closeKeyListener);
  }

  // This is necessary to change search input value when user uses
  // <- and -> in the browser (native history navigation)
  componentDidUpdate(prevProps) {
    if (prevProps.initialQuery !== this.props.initialQuery) {
      this.setState({ value: this.props.initialQuery });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.closeKeyListener);
  }

  render() {
    const { placeholder, asyncresults, initialQuery, searchQueries, isInOverlay } = this.props;
    const { focusIndex, showSearchProposals } = this.state;
    let { value } = this.state;

    const classes = ClassNames({
      "search-proposals": true,
      "extra-padding": isInOverlay
    });

    let searchProposalsContent = (asyncresults.length && showSearchProposals)
      ? (
        <ul className={classes}>
          {
            asyncresults.map((item, i) => {
              item.hasFocus = i + 1 === focusIndex;
              return createSearchResult(item, i, value, this.setValueAndSubmit);
            })
          }
        </ul>
      )
      : null;

    let searchProposals = isInOverlay
      ? <div className={styles.proposalsOuter}>
        <div className={styles.container}>
          {searchProposalsContent}
        </div>
      </div>
      : searchProposalsContent;

    // if an item has focus set that as value
    if (focusIndex > 0)
      value = asyncresults[focusIndex - 1].title;

    return (
      <>
        <form
          onSubmit={this.onSubmit}
          action={this.props.searchUri}
          method="get"
          autoComplete="off"
          ref={(form) => this.form = form}
        >
          <div className={styles.container}>
            <SearchInput
              value={value}
              name="q"
              onChange={this.onChange}
              onSearchClick={this.onSubmit}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              placeholder={placeholder}
              withReset={false}
              autoFocus={!initialQuery} // no focus when presenting initialquery
              ref={(input) => this.input = input}
            />
            {showSearchProposals && searchQueries?.map(createHiddenField)}
          </div>
        </form>
        {(!value && this.state.isAuthenticated) &&
          <Suspense>
            <ReadingList isInOverlay={isInOverlay} closeOverlay={this.props.onClose} />
          </Suspense>
        }
        {searchProposals}
      </>
    );
  }
}

function onChange(value) {
  if (!value)
    this.input.focus();
  // reset index in searchproposals when value changes
  const focusIndex = 0;

  this.setState({ value, focusIndex, showSearchProposals: true });
  this.props.onQuery(value);
}

function clearSearchProposals() {
  this.setState({ showSearchProposals: false, focusIndex: 0 });
}

function onSubmit(event) {
  if (event && event.preventDefault)
    event.preventDefault();

  if (this.state.focusIndex !== 0) {
    let chosenValue = this.props.asyncresults[this.state.focusIndex - 1].title;
    this.setState({ value: chosenValue }, () => {
      if (!this.state.value) return;
      // Creating query here to wait for updated state
      // encode query value, trim, convert multiple "spaces/whitespaces" to single space and finally convert ' ' => +
      const queryValue = encodeURIComponent(this.state.value.trim().replace(/\s+/g, ' '))
        .replace(/%20/g, '+');

      const url = `${this.props.searchUri}?q=${queryValue}`;
      this.props.onSubmit(url, clearSearchProposals.bind(this), this.state.value);
    });
  } else {
    if (!this.state.value) return;
    // encode query value, trim, convert multiple "spaces/whitespaces" to single space and finally convert ' ' => +
    const queryValue = encodeURIComponent(this.state.value.trim().replace(/\s+/g, ' '))
      .replace(/%20/g, '+');

    const url = `${this.props.searchUri}?q=${queryValue}`;
    this.props.onSubmit(url, clearSearchProposals.bind(this), this.state.value);
  }
}

function setValueAndSubmit(value, event) {
  // set state and then submit form.
  // this is used for search-proposals, when reacting to clicks
  this.setState({ value }, () => {
    this.onSubmit(event);
  });
}

function closeKeyListener(event) {
  if (event.which === 27) {
    if (!this.state.value) {
      if (this.props.onClose)
        this.props.onClose();
    }
  }
}

function createSearchResult(item, i, value, onClick) {
  const classes = ClassNames({
    "item": true,
    "focus": item.hasFocus
  });

  const marked = value;
  const remaining = item?.title.substring(value.length, item.title.length);

  return (
    <li
      className={classes}
      key={i}
      onClick={onClick.bind(null, item.title)}
    >
      <strong className="marked">{marked}</strong>
      <span className="remaining">{remaining}</span>
    </li>
  )
}

function onFocus(event) {
  document.addEventListener('keydown', this.updateListIndex);

  if (this.props.onFocus)
    this.props.onFocus(event);
}

function onBlur(event) {
  document.removeEventListener('keydown', this.updateListIndex);
  this.setState({ focusIndex: 0 });
}

function updateListIndex(event) {
  if (event.which !== 38 && event.which !== 40)
    return;

  if (!this.state.showSearchProposals) return;

  event.preventDefault();
  const resultsCount = this.props.asyncresults.length;
  let focusIndex = Math.abs(this.state.focusIndex);

  switch (event.which) {
    case 40: // down arrow
      focusIndex = focusIndex + 1;
      break;
    case 38: // up arrow
      focusIndex = focusIndex > 0
        ? focusIndex - 1
        : resultsCount;
      break;
  }

  if (focusIndex > resultsCount)
    focusIndex = 0;

  if (focusIndex < 1)
    focusIndex = 0;

  this.setState({ focusIndex });
}

function createHiddenField(query, i) {
  return (
    <input name={query.key} value={query.value} type="hidden" key={i} />
  )
}

export default SearchProposalHOC(SearchComponent, 10, 2);