import React from "react";
import { hydrateRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route
} from "react-router-dom";

import setupI18n from "./client/i18n";
import { config } from '@fortawesome/fontawesome-svg-core';
import setupWorkbox from "./client/configs/workboxregistration";
import logClientError from "./client/errors/consoleErrors.js";
import AppWrapper from "./client/app-wrapper";
config.autoAddCss = false; // make font awesome not include styles as already load those with our bundles

(async () => {

  setupWorkbox();
  await setupI18n(window.i18n);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="*" element={
        <>
          <AppWrapper />
          {/* <ScrollRestorationStateKeeper />
          <App
            menu={window.menu}
            user={window.user}
            config={window.config}
            pageData={window.pageData}
            requestLanguage={window.requestLanguage}
          /> */}
        </>
      } >
      </Route>
    )
  );

  const renderApp = () => {
    const rootElement = document.getElementById("root");

    const app = <RouterProvider router={router} />;

    hydrateRoot(rootElement, app, { onRecoverableError: (err, errorInfo) => {
        logClientError({
          message: err.message,
          stack: err.stack,
          componentStack: errorInfo.componentStack,
          digest: errorInfo.digest,
          recoverable: true
        });
      }
    });

  };

  renderApp();

  // const swVersion = await wb.messageSW({ type: 'GET_VERSION' });
  // console.log('Service Worker version:', swVersion);

})();
