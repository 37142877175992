export default function setupWorkbox() {
  if ('serviceWorker' in navigator) {
    const promise = navigator.serviceWorker.register(
      import.meta.env.MODE === 'production' ? '/serviceworker.js' : '/dev-sw.js?dev-sw'
      ,{ type: import.meta.env.MODE === 'production' ? 'classic' : 'module' }
    )
    promise.then((registration) => {
      // "updatefound" is triggered both on install and on updates
      registration.addEventListener("updatefound", () => {
        const sw = registration.installing;
        if (sw) {
          sw.addEventListener("statechange", (e) => {
            if (e.target.state === "activated") {
              sw.postMessage({ updateType: "refreshCache" });
            }
          });
        } else console.log("no sw/controller available");
      });
    })
  }
} 
