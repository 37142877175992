import { useContext, useEffect } from "react";
import {
  scrollPositions,
  firstPageLoad,
  setFirstPageLoad,
  setScrollRestorationInProgress,
  trueUrlHash
} from "./scroll-restore-sev";
import { useLocation } from "react-router-dom";
import SearchContext from "../contexts/search";

/**
 * Hook used to watch when a component renders based on the given trigger.
 * This function will trigger scroll restoration when the component using this hook
 * is rendered in the browser. 
 * @param {} trigger Should be the most vital part of a given view, for example the body (webbook content) on a webbook page or a list of items on a category page
 * @param {boolean} infiniteScrollSpecialCase Some pages have infinite scroll functionality with no indication of any type of pagination. On pages like this, we just scroll to the top.
 */
export function useScrollRestoringRenderWatcher(trigger, infiniteScrollSpecialCase) {
  const location = useLocation();
  const { flipCloseSearchOverlayTrigger } = useContext(SearchContext);

  useEffect(() => {
    if (!trigger) return;
    // performance.mark("component_render_start" + `${trigger}`.substring(0, 35));

    requestAnimationFrame(() => {
      setScrollRestorationInProgress(true);
      // performance.mark("component_render_end" + `${trigger}`.substring(0, 35));
      // performance.measure('measure' + `${trigger}`.substring(0, 35), 'component_render_start' + `${trigger}`.substring(0, 35), 'component_render_end' + `${trigger}`.substring(0, 35));
      if (infiniteScrollSpecialCase) {
        window.scroll(0, 0);
        flipCloseSearchOverlayTrigger();
        setScrollRestorationInProgress(false);
        return;
      }
      // performance.mark("calling_initiateScrollRestoration");
      flipCloseSearchOverlayTrigger();
      initiateScrollRestoration(location);
    })

  }, [trigger]);
}

function initiateScrollRestoration(location) {
  // console.log("true hash from scroll restoration component:", trueUrlHash);
  // We won't try to restore any specific scroll position on the front page
  // because we set focus in the search input tag
  if (location.pathname === "/") {
    if (firstPageLoad) {
      // console.log("first page load, skipping scroll restoration");
      setFirstPageLoad(false);
      setScrollRestorationInProgress(false);
      return;
    }
    window.scrollTo(0, 0);
    setScrollRestorationInProgress(false);
    return;
  }
  const scrollYValue = scrollPositions[location.key];
  restoreScrollPosition(scrollYValue);
  setScrollRestorationInProgress(false);
}

function restoreScrollPosition(scrollY) {
  if (new URL(window.location).hash === "#reviews") {
    const scrollTarget = document.getElementById("reviews");
    if (scrollTarget)
      window.scroll({ top: scrollTarget.offsetTop, behavior: "smooth" });
    else
      window.scrollTo(0, 0);

    if (firstPageLoad)
      setFirstPageLoad(false);
    return;
  }
  if (firstPageLoad) {
    // console.log("first page load, skipping scroll restoration SSRW");
    setFirstPageLoad(false);
    return;
  } else if (typeof scrollY === "number") {
    // console.log("scrolling to specific y:", scrollY, " SSRW");
    window.scrollTo(0, scrollY);
  } else if (trueUrlHash) {
    const scrollTarget = document.getElementById(trueUrlHash.substring(1));

    if (scrollTarget) {
      // console.log("scrolling to hash insidea renderwatcher");
      window.scroll({ top: scrollTarget.offsetTop, behavior: "auto" });
      return;
    }
  } else {
    // console.log("scrolling to top SSRW");
    window.scrollTo(0, 0);
  }
  // performance.mark("bottom_of_actual_scroll_restoration SSRW");
}