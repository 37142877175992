const templateTypes = {
  FRONTPAGE: "FrontPage",
  FAQ: "FAQ",
  FAQ_CATEGORY: "FAQCategory",
  FAQ_ENTRY: "FAQEntry",
  INFO: "InfoPage2017",
  CATEGORY: "Category",
  SEARCH: "SearchPage",
  HOME: "Home",
  AUTHOR: "Author2017",
  AUTHORS: "AuthorList2017",
  MARKETING: "Marketing",
  WEBBOOKWIDE: "WebbookWiderTextArea",
  TEXTLIST: "TextList2017",
  EXAM: "Exam",
  EXAM_EDUCATIONS: "examEducations",
  EXAM_ASSIGNMENTS: "examAssignments",
  APP_DOWNLOAD: "LearnerApp"
};

export default templateTypes;