import { useContext, useState } from 'react';
import MenuContext from '../../contexts/menu';
import styles from '../../styles/components/header/menu.module.scss';
import MenuDesktop from './menu-desktop';
import MenuMobile from './menu-mobile';
import UserMenuDesktop from './user-menu-desktop';
import DropdownContext from '../../contexts/megaDropdown';

export default function Menu({ mobileBurgerMenuState, setMobileBurgerMenuState }) {
  // Extra data for MegaDropdown / submenu on mobile is cached here
  const [dropdownData, setDropdownData] = useState({});
  const { menu } = useContext(MenuContext);
  const { userMenu, topMenu } = menu;

  return (
    <DropdownContext.Provider value={{
      dropdownData,
      setDropdownData
    }}>
      <div className={styles.headerMenu}>
        {
          !!(userMenu?.length || topMenu?.length) &&
          <MenuMobile active={mobileBurgerMenuState} setActive={setMobileBurgerMenuState} />
        }
        <MenuDesktop />
        <UserMenuDesktop />
      </div>
    </DropdownContext.Provider>
  );
}
