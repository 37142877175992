import { createContext } from "react";

const MenuContext = createContext({
  menu: {
    userMenu: [],
    infoMenu: [],
    topMenu: [],
    footerMenu: {},
  },
  setMenu: (menu) => { },
})
export default MenuContext;