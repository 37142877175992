import reportAndThrowError from "../errors/errorHandle";
/**
 *
 * @param {import("react-i18next").TFunction} t
 * @param {Number} errorCode
 * @returns {{title:String, content:String}}
 */
export function getErrorMessages(t, errorCode = 500) {
  if (!t)
    reportAndThrowError(new Error("`t` (i18next) is not defined"));

  switch (errorCode) {
    case 404:
      return {
        title: t("error.404_title")
        , content: t("error.404_content")
      };
    case 503:
      return {
        title: t("error.503_title")
        , content: t("error.503_content")
      };
    default:
      return {
        title: t("error.generic_title")
        , content: t("error.generic_content")
      };
  }
}