import { createContext } from "react";

const ReviewContext = createContext({
  /**
   * @typedef {{rating: Number, body: String, timestamp: String, comment: String|null, author: String}} review
   * @typedef {{rating: String, count: Number, reviews: Array<review>}} reviews
   * @type {reviews} reviews
   */
  reviews: {
    reviews: [],
    rating: 1,
    count: 1
  },
  /**
   * @typedef {{rating: Number, body: String, timestamp: String, comment: String|null, author: String}} review
   * @typedef {{rating: String, count: Number, reviews: Array<review>}} reviews
   * @param {reviews} reviews
   */
  setReviews: (reviews) => {},
  userCanWriteReviews: false,
  setUserCanWriteReviews: () => {}
});
export default ReviewContext;