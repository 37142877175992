import Fetcher from '../utils/fetcher';

/**
 * 
 * @param {String} type 
 * @param {Object} log 
 */
export default async function logClientEvent(type, log) {

    const fetcher = new Fetcher();
    await fetcher.fetch(`/api/event/${type}`, "POST", log);
}