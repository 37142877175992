import Cache from './sessionCache';
import Debounce from 'lodash.debounce';
import reportAndThrowError from '../../../errors/errorHandle';

/*
Tænkt som et generel "query" object, der kan hente data og gemme i cache
*/
class RemoteQueryEngine {
    constructor() {
        // properties
        this.limit = 10;
        this.transformer = (x) => { return x };
        this.resultHandler = (x) => { return [x] };
        this.queryDelay = 200;
        this.cache = null;
    }

    // query method called by consumer with a query string.
    // this method is returned from the init method.
    query(q) {
        // check for cached results 
        const cachedValue = this.retrieveCached(q);
        if (cachedValue) {
            this.resultHandler(cachedValue);
            return;
        }

        // replace wildcard with query
        const url = this.url.replace(this.wildcard, encodeURIComponent(q));

        // perform query
        window.fetch(url, {credentials: "same-origin"})
            .then(this.transformer)
            .then((value) => {
                this.addToCache(q, value);
                return value;
            })
            .then(this.resultHandler)
            .catch((response) => {  
                reportAndThrowError(new Error(response));
            });
    }

    // fluent methods -----------------------

    setLimit(limit) {
        this.limit = limit;
        return this;
    }

    setRemote({
        url = '',
        wildcard = '%QUERY',
        transformer = null
    } = config) {
        this.url = url;
        this.wildcard = wildcard;
        this.transformer = transformer || this.transformer;
        
        return this;
    }

    setResultHandler(f) {
        this.resultHandler = f;
        return this;
    }

    setCustomCaching(factory) {
        // setting factory param to false will disable caching
        if (!factory)
            this.cache = null;

        return this;
        // todo implement custom caching mechanism
    }

    setQueryDelay(ms){
        if (!Number.isInteger(ms))   
            reportAndThrowError(new Error("QueryDelay is not an integer. For how long, in milliseconds, should I wait before querying resources ?"));
        
        this.queryDelay = ms;
        return this;
    }
    
    init() {
        if (!this.url) reportAndThrowError(new Error('Missing url in remote config object. With whom should I query ?'));
        if (!this.resultHandler) reportAndThrowError(new Error('Missing resultHandler. What should I do with the data then ?'));
 
        // set default caching
        if (!this.cache) {
            const cache = new Cache({name: 'RemoteQueryEngine'}).init();
            if (cache) this.cache = cache;
        }
        
        // from init we return a debounced query method.
        return Debounce(this.query.bind(this), this.queryDelay);
    }

    // caching  -------------------------------

    retrieveCached(q) {
        if (!this.cache) return null;
        
        const cachedVals = this.cache.getCached(q);
        if(!cachedVals) return null;
        
        return cachedVals;
    }

    addToCache(q, value) {
        if (!this.cache)
            return;

        this.cache.setCached(q, value);
    }
}

export default RemoteQueryEngine;
