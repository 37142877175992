import { startTransition, useEffect, useState } from "react";
import { defaultOGImageUri, defaultViewport } from "../../common/constants/uiConstants";

export default function HeadTags({ children }) {
  const [links, setLinks] = useState([]);
  const [meta, setMeta] = useState([]);
  const [scripts, setScripts] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
    return removeRobotsMetatag;
  }, []);

  function removeRobotsMetatag() {
    try {
      const head = document.querySelector(`head`); 
      const noIndexQuery1 = `meta[name="robots"]`;
      const noIndexQuery2 = `meta[name="Robots"]`;
      const noIndexTag = document.querySelector(noIndexQuery1);
      const noIndexTag2 = document.querySelector(noIndexQuery2);
      if (noIndexTag) head.removeChild(noIndexTag);
      if (noIndexTag2) head.removeChild(noIndexTag2);
    } catch (error) {
    }
  }

  useEffect(() => {
    startTransition(() => {
      if (Array.isArray(children)) {
        let metaTags = [];
        let linkTags = [];
        let scriptTags = [];
        children.forEach((child) => {
          if (child.type === "title") {
            setTitle(child);
            return;
          }
          if (child.type === "meta") {
            metaTags.push(child);
            return;
          }
          if (child.type === "link") {
            linkTags.push(child);
            return;
          }
          if (child.type === "script") {
            scriptTags.push(child);
            return;
          }
        })
        setMeta(metaTags);
        setLinks(linkTags);
        setScripts(scriptTags);
      } else {
        if (children.type === "title") {
          setTitle(children);
        }
        if (children.type === "meta") {
          let metaTags = [];
          metaTags.push(children);
          setMeta(metaTags);
        }
        if (children.type === "link") {
          let linkTags = [];
          linkTags.push(children);
          setLinks(linkTags);
        }
        if (children.type === "script") {
          let scriptTags = [];
          scriptTags.push(children);
          setScripts(scriptTags);
        }
      }
    });
  }, [children]);

  useEffect(() => {
    const head = document.querySelector(`head`); 

    if (title) {
      let currentTitle = document.querySelector("title");
      if (!currentTitle) {
        let titleTag = document.createElement("title");
        titleTag.innerHTML = title.props.children;
        head.appendChild(titleTag);
      } else {
        currentTitle.innerHTML = title.props.children;
      }
    }
  }, [title]);

  useEffect(() => {
    const head = document.querySelector(`head`); 

    // Always delete canonical, next and prev link - some pages don't have these tags 
    // and therefore the if(links[0])-guard would be skipped

    const nextTag = document.querySelector(`link[rel="next"]`);
    const prevTag = document.querySelector(`link[rel="prev"]`);
    const canonicalTag = document.querySelector(`link[rel="canonical"]`);
    if (nextTag) head.removeChild(nextTag);
    if (prevTag) head.removeChild(prevTag);
    if (canonicalTag) head.removeChild(canonicalTag);
    
    if (links[0]) {
      let linkTags = [];
      links.forEach((element) => {
        let tag = document.createElement("link");
        let keys = Object.keys(element.props);
        if (!keys) return;
        keys.forEach((key) => {
          tag[key] = element.props[key];
        });
        linkTags.push(tag);
      });

      linkTags.forEach(e => {
        head.appendChild(e);
      });
    }
  }, [links]);

  useEffect(() => {
    const head = document.querySelector(`head`); 
    removeRobotsMetatag();
    const ogImageTag = document.querySelector(`meta[property="og:image"]`);
    const viewportTag = document.querySelector(`meta[name="viewport"]`);
    if (ogImageTag) head.removeChild(ogImageTag);
    if (viewportTag) head.removeChild(viewportTag);
    if (meta[0]) {
      let metaTags = [];
      let ogImageTagFound = false;
      let viewportTagFound = false;
      meta.forEach((element) => {
        let tag = document.createElement("meta");
        let keys = Object.keys(element.props);
        if (!keys) return;
        keys.forEach((key) => {
          if (element.props[key] === "og:image") ogImageTagFound = true;
          else if (element.props[key] === "viewport") viewportTagFound = true;
          tag.setAttribute(key, element.props[key]);
        });
        metaTags.push(tag);
      });
      if (!ogImageTagFound) {
        const tag = document.createElement("meta");
        tag.setAttribute("property", "og:image");
        tag["content"] = defaultOGImageUri;
        metaTags.push(tag);
      }
      if (!viewportTagFound) {
        const tag = document.createElement("meta");
        tag.setAttribute("name", "viewport");
        tag["content"] = defaultViewport;
        metaTags.push(tag);
      }
      metaTags.forEach(e => {
        const existingChild = document.querySelector(`meta[name="${e.name}"]`);
        if (existingChild) head.removeChild(existingChild);
        head.appendChild(e);
      });
    }
  }, [meta]);

  useEffect(() => {
    const head = document.querySelector(`head`); 
    const structuredDataTags = document.querySelectorAll(`script[type="application/ld+json"]`);
    structuredDataTags.forEach((structuredDataTag) =>  {
      head.removeChild(structuredDataTag);
    })
    if (scripts[0]) {
      let scriptTags = [];
      scripts.forEach((element) => {
        let tag = document.createElement("script");
        let keys = Object.keys(element.props);
        if (!keys) return;
        keys.forEach((key) => {
          if(key == "children") {
            tag.innerText = element.props[key];
          } else {
            tag.setAttribute(key, element.props[key]);
          }
        });
        scriptTags.push(tag);
      });
      scriptTags.forEach(e => {
        head.appendChild(e);
      });
    }
  }, [scripts]);

  return <></>;
}