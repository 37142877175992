import Fetcher from '../utils/fetcher';

export default class ContentFacade {
  #fetcher;

  constructor() {
    this.#fetcher = new Fetcher();
  }

  /**
   * Get any type of content from the content API
   * @param {String} path 
   * @param {Function} claimsCallback
   * @returns {Promise<Object>}
   */
  getContent = async (path, claimsCallback) => {
    path = path.startsWith('/') ? path.substring(1) : path;

    const response = await this.#fetcher.fetch(`/api/content/${path}`)
    const responseJson = await response.json();

    if (claimsCallback) {
      await claimsCallback(responseJson.claims);
    }

    // Claims should never be part of the actual content
    delete responseJson.claims;
    return responseJson;
  }
}