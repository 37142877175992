import { ignoredPathPrefixes } from "../ignoredPathPrefixes";

/**
 * 
 * @param {String} url 
 * @param {Object} keyValues 
 * @returns {String}
 */
export function appendSearchParams(url, keyValues) {
  if (!url)
    throw new Error("URL is missing");

  if (!keyValues)
    throw new Error("KeyValues is missing");

  if (Object.keys(keyValues).length === 0)
    return url;

  let separatorCharacter = url.indexOf('?') >= 0 ? '&' : '?';
  try {

    var queryString = '';
    Object.keys(keyValues).forEach((key) => {
      queryString += `${separatorCharacter}${key}=${keyValues[key]}`;
      if (separatorCharacter === '?')
        separatorCharacter = '&';
    });

    return `${url}${queryString}`;
  } catch (error) {
    if (error.message.startsWith("Invalid URL:")) {
      throw new Error("URL is invalid");
    }
  }
}

/**
 * 
 * @param {String} search 
 * url query fragment from location.search
 * @param {String} key
 * url query key to retreive values from
 */
export function getSearchParam(search, key) {

  if (!search)
    return

  if (!key)
    return

  try {
    const params = new Proxy(new URLSearchParams(search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    return params[key];
  } catch (error) {

  }

}

/**
 * 
 * @param {string} href 
 * @returns {boolean}
 */
export function isExternalLink(href) {
  if (!href) return false;

  const isInIgnoreList = ignoredPathPrefixes.some(prefix => {
    return href.startsWith(prefix) || href.startsWith(`/${prefix}`);
  });

  return isInIgnoreList || href.toLowerCase().startsWith('http');
}