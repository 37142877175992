import reportAndThrowError from "../../../errors/errorHandle";
class SessionCache {
    constructor({name = ''} = settings) {
        this.name = name;
    }

    getCached(q) {
        const values = sessionStorage.getItem(`${this.name}-${q}`);
        if (!values) return null;

        return JSON.parse(values);
    }

    setCached(query, values) {
        if(!Array.isArray(values))
            reportAndThrowError(new Error('Cant set cache. Value must be an array'));

        sessionStorage.setItem(`${this.name}-${query}`, JSON.stringify(values));
    }

    init() {
        if (!window.sessionStorage) return false;

        return this;
    }
}

export default SessionCache;