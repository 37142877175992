import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import ConfigContext from '../../contexts/config';
import MenuContext from '../../contexts/menu';
import styles from '../../styles/components/header/menu-mobile-overlay.module.scss';
import classNames from '../../utils/classNames';
import { isExternalLink } from '../../../common/utils/url';
import { ArrowLeft, ArrowRight, ChevronRight } from '../icons';
import Fetcher from '../../utils/fetcher';
import DropdownContext from '../../contexts/megaDropdown';

const subMenuResetData = { title: "", items: [] };
/**
 * @type {Fetcher}
 */
let fetcher;

export default function MenuMobileOverlay() {
  const { menu } = useContext(MenuContext);
  const config = useContext(ConfigContext);
  const { dropdownData: cachedData, setDropdownData: setCachedData } = useContext(DropdownContext);
  const [subMenuData, setSubMenuData] = useState(subMenuResetData);
  const userMenu = menu.userMenu;
  const topMenu = menu.topMenu;

  function printMainItems(item, i) {
    const isExternal = isExternalLink(item.uri);

    /**
     * Get menu data for megadropdown/submenu in mobile view
     * @param {String} uri
     * @param {Fetcher} fetcher 
     */
    async function getSubMenuData(uri, fetcher) {
      try {
        /**
         * @typedef {{title: String, uri: String, tooltip: String, active: boolean, children: Array<menuItem>}} menuItem
         * @type {{type: String, value: Array<menuItem>}}
         */
        let dropdownData;

        if (cachedData[uri]) {
          dropdownData = cachedData[uri];
        } else {
          dropdownData =
            await fetcher.fetch("api/content" + uri + "?view=menu")
              .then(res => res.json());
          if (!dropdownData
            || !Array.isArray(dropdownData.value)
            || !dropdownData.value.length
          ) {
            // No dropdown data available
            return [];
          }
          cachedData[uri] = dropdownData;
          setCachedData(cachedData);
        }
        return dropdownData.value;
      } catch (err) {
        return []
      }
    }

    async function onClick(uri, title, setSubMenuState) {
      if (!fetcher) fetcher = new Fetcher();
      const subMenuItems = await getSubMenuData(uri, fetcher);
      setSubMenuState({
        title,
        items: subMenuItems
      })
    }

    return (
      <li
        key={i}
      >
        {
          item.dropdown &&
          <>
            <div
              className={styles.title}
              onClick={() => onClick(item.uri, item.title, setSubMenuData)}
            >
              {item.title}
            </div>
            <div className={styles.nextLevel}><ArrowRight /></div>
          </>
        }
        {
          !item.dropdown &&
          <>
            {
              isExternal
                ? <a href={item.uri} className={styles.title}>{item.title}</a>
                : <Link to={item.uri} className={styles.title}>{item.title}</Link>
            }
          </>
        }
      </li>
    );
  }

  function printUserItems(item, i) {
    const items = [];
    const isExternal = isExternalLink(item.uri);
    items.push(
      <li
        key={`${i}mob`}
      >
        <div className={styles.title}>
          {!!isExternal && <a href={item.uri}>{item.title}</a>}
          {!isExternal && <Link to={item.uri}>{item.title}</Link>}
        </div>
      </li>
    );
    if (item.children) {
      items.push(item.children.map((e) => printUserItems(e, i + 100)));
    }
    return items;
  }

  /**
   * @typedef {{title: String, uri: String, tooltip: String, active: boolean, children: Array<menuItem>}} menuItem
   * @param {Array<menuItem>} items 
   */
  function printSubMenu(items) {
    return items.map((item, i) => {
      const isExternal = isExternalLink(item.uri);
      return (
        <li key={i}>
          <ChevronRight width="8px" height="8px" />
          {
            isExternal
              ? <a href={item.uri} title={item.tooltip} className={styles.title}>{item.title}</a>
              : <Link to={item.uri} title={item.tooltip} className={styles.title}>{item.title}</Link>
          }
        </li>
      )
    });
  }

  const views = [];
  const view1classes = classNames([
    styles.listView,
    styles.view1
  ])

  const view2classes = classNames([
    styles.listView,
    styles.view2
  ])

  views.push(
    <div key={`memobov`} className={view1classes}>
      <nav>
        <ul className={styles.listMain}>
          {topMenu.map(printMainItems)}
        </ul>
      </nav>
      <ul className={styles.listUser}>
        {userMenu.map(printUserItems)}
      </ul>
    </div>
  );

  views.push(
    <div key={`memosub`} className={view2classes}>
      <header>
        <div className={styles.title}>{subMenuData.title}</div>
        <div className={styles.prevLevel} onClick={
          () => { setSubMenuData(subMenuResetData) }
        }>
          <ArrowLeft className={styles.prevLevel} />
        </div>
      </header>
      <ul className={styles.subList}>
        {!!subMenuData.items?.length &&
          printSubMenu(subMenuData.items)
        }
      </ul>
    </div>
  )

  return (
    <div className={`${styles.overlayMenuMobile} ${!!subMenuData.items?.length ? styles.showSubList : ""}`}>
      {views}
    </div>
  );
}