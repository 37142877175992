import styles from '../../styles/components/footer/footer.module.scss';
import FooterMenu from './menu';
import { useTranslation } from 'react-i18next';

export default function Footer({ narrow = false }) {
  const { t } = useTranslation();
  const arrowText = t('footer.back_to_top');
  return (
    <footer className={styles.footerMain}>
      <FooterMenu narrow={narrow} />

      <div className={styles.meta}>
        <div className={styles.address} dangerouslySetInnerHTML={{
          __html: t('footer.address_html')
        }}>
        </div>
        <div className={styles.goToTop}>
          <a href="#" onClick={(e) => {
            e.preventDefault();
            window.scroll({ top: 0 })
          }}>
            <span className={styles.text} id="goToTopText">
              {arrowText}
            </span>
            <svg width="32px" height="32px" viewBox="0 0 30 30" className={`svg-icon ${styles.iconArrow}`} role="img" aria-labelledby="goToTopText">
              <g transform="rotate(-90 15 15)">
                <circle cx="15" cy="15" r="15" fill="#fff"></circle>
                <rect x="13" y="10" width="2" height="10" transform="translate(-1 29) rotate(-90)" fill="#0075e3"></rect>
                <polyline points="16.77 19.76 20.26 15.18 16.84 10.69" fill="none" stroke="#0075e3" strokeMiterlimit="10" strokeWidth="2"></polyline>
              </g>
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
}