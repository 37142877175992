import { useTranslation } from "react-i18next";
import styles from "../styles/pages/reloadingErrorPage.module.scss";

export default function ReloadingErrorPage() {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.errorDiv}>
        <p>
          {t("error.automatic_reload_error_page",
          "A new version of the site is available - If the site doesn't reload automatically, please press F5.")}
        </p>
      </div>
    </>
  );
}