import { createContext } from "react";

const StatusContext = createContext(
  { 
    message: "", 
    /**
     * @typedef {"success"|"failure"} type
     * @type type
     */
    type: "",
    /**
     * 
     * @param {String} message 
     * @param {"success"|"failure"} type 
     */
    setStatus: (message, type) => {},
  }
  );
export default StatusContext;