export default class Fetcher {
  /**
   * Fetches stuff
   * @param {String} url
   * @param {String} method
   * @param {any} body
   * @param {Object} headers
   * @param {any} signal
   * @param {boolean} disableUnauthorizedLoginRedirect 
   * @returns response promise
   */
  fetch(url, method = "GET", body, headers, signal, disableUnauthorizedLoginRedirect) {
    const options = this._makeOptions(method, body, headers, signal);

    if (!url.startsWith("http://") && !url.startsWith("https://") && !url.startsWith('/'))
      url = "/" + url;

    return fetch(url, options)
      .then((response) => {
        if (response.ok) {
          return response;
        }
        switch (response.status) {
          case 401:
            if (!disableUnauthorizedLoginRedirect) {
              window.location = '/profile/login';
              return;
            }
            else {
              return Promise.reject(response);
            }
          default:
            return Promise.reject(response);
        }
      });
  }

  _makeOptions(method, body, headers, signal) {
    const opts = {
      method,
      headers: headers || { 'Content-Type': 'application/json' },
      body: body ? JSON.stringify(body) : null,
      signal
    };
    return opts;
  }
}