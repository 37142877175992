import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/**
 * Configures i18n for use in client
 * @param {{language: String, resources: Object}} i18n 
 */
export default async function setupI18n({ language, resources }) {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        [language]: {
          translation: resources[language],
        }
      },
      lng: language
    });
}