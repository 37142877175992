function Search() {
  return (
      <g>
        <path
          d="M7,14a7,7,0,1,1,7-7A7,7,0,0,1,7,14ZM7,2a5,5,0,1,0,5,5A5,5,0,0,0,7,2Z"
          fill="#1f2d38"
        ></path>
        <rect
          x="14.13"
          y="12.23"
          width="2"
          height="5.66"
          transform="translate(-6.22 15.11) rotate(-45)"
          fill="#1f2d38"
        ></rect>
      </g>
  );
}

export default Search;
