import { useEffect, useState } from "react";

/**
 * @type {URL|null}
 */
let initalState;

/**
 * @type {"popstate"|"click"}
 */
let urlChangeReasonType = "";

/**
 * Utility used to watch the URI based on the following browser events:
 * - popstate
 * - click
 */
export function useUrlWatcher() {
  const [url, setUrl] = useState(initalState);
  const [urlChangeReason, setUrlChangeReason] = useState(urlChangeReasonType);

  useEffect(() => {
    function attemptUrlUpdate(e) {
      if (e.type === "click") {
        // We are only interested in click events on anchor tags
        if (!e.target?.nodeName && e.target.nodeName !== "A") return;

        try {
          const newURI = e.target.baseURI;
          setUrl(new URL(newURI));
          setUrlChangeReason("click");
          // console.log("setting url state with new URI:", newURI);
        } catch (err) {
          // console.log("URL watcher failed to update url state", err);
        }
      } else if (e.type === "popstate") {
        try {
          const newURI = e.target.location.href;
          setUrl(new URL(newURI));
          setUrlChangeReason("popstate");
          // console.log("setting url state with new URI:", newURI);
        } catch (err) {
          // console.log("URL watcher failed to update url state", err);
        }
      }
    }

    window.addEventListener("popstate", attemptUrlUpdate);
    window.addEventListener("click", attemptUrlUpdate);
    
    return () => {
      window.removeEventListener("popstate", attemptUrlUpdate);
      window.removeEventListener("click", attemptUrlUpdate);
    }
  }, []);

  return {url, urlChangeReason};
}