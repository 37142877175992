import { useContext, useEffect } from "react";
import UserContext from "../../contexts/user";

let heartbeatInterval = null;
export default function Heartbeat({ interval = 300000 }) { // 5 min = 5 * 60 * 1000 = 300000

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user?.authenticated) {
      clearInterval(heartbeatInterval);
      heartbeatInterval = null;
      return;
    }

    heartbeatInterval = setInterval(async () => {
      const response = await fetch('/profile/api/session/heartbeat', { method: 'POST' });

      if (!response.ok)
        console.error(`Heartbeat failed with status code ${response.status}`);

    }, interval);

    return () => {
      if (heartbeatInterval) {
        clearInterval(heartbeatInterval);
      }
    };
  }, [user]);


  return null;
}