import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useUrlWatcher } from "./urlWatcher";
import { getSearchParam } from "../../common/utils/url";
import isEqual from "lodash.isequal";

export let scrollPositions = {};
export let firstPageLoad = true;
export let scrollRestorationInProgress = false;
export let trueUrlHash = "";
/**
 * @param {boolean} input
 */
export function setFirstPageLoad(input) {firstPageLoad = input};
/**
 * @param {boolean} input
 */
export function setScrollRestorationInProgress(input) {scrollRestorationInProgress = input};

let prevPath;

// Query parameters that are used to load a new page on the same path
const queryParametersThatChangePage = [
  "page",
  "year", // used in activities and exam
  "category",
  "q",
  "exam"
]

/**
 * This component stores scroll positions used for scroll restoration and triggers 
 * scroll restoration in certain scenarios. 
 * RENDER THIS COMPONENT ONLY ONCE
 */
export default function ScrollRestorationStateKeeper() {
  const [prevRelevantSearchParams, setPrevRelevantSearchParams] = useState({});
  const location = useLocation();
  const { url, urlChangeReason } = useUrlWatcher();
  const [trueHash, setTrueHash] = useState(() => {
    if (window) {
      return window.location?.hash;
    }
    return "";
  });

  useEffect(() => {
    setTrueHash(url?.hash ? url.hash : "");
  }, [url]);

  useEffect(() => {
    addScrollEventListener();
  }, []);


  // ------------------------------------------ //
  // Group of scroll restoration triggers BEGIN //
  // ------------------------------------------ //

  useEffect(() => {
    // console.log("hash changed:", trueHash);
    // We only react to hash changes in the url if user clicked the back/forward button
    // in the browser
    if (urlChangeReason === "popstate") {
      // console.log("trueHash:", trueHash,
      //   "| prevPath:", prevPath,
      //   "| loc.panm", location.pathname
      // );
      if (!trueHash && prevPath == location.pathname) {
        // console.log("hash scrolling triggered, scrolling to top");
        window.scroll(0, 0);
        return;
      } else if (prevPath == location.pathname){
        // console.log("hash scrolling triggered by popstate, scrolling to hash:", trueHash);
        initiateScrollRestoration(trueHash);
      }
    }
    // If the hash changed due to the user clicking a link, scrolling to the relevant heading
    // has to happen after the next page is rendered which means renderWatcher.jsx has to
    // trigger the render

    // prevPath is only relevant in situtations where the user updates the hash in the url in some way
    prevPath = location.pathname;
    trueUrlHash = trueHash;
  }, [trueHash]);

  useEffect(() => {
    return () => {
      scrollRestorationInProgress = true;
      const relevantSearchParams = createRelevantSearchParamsObj(location.search);
      setPrevRelevantSearchParams(relevantSearchParams);
    }
  }, [location.pathname]);

  useEffect(() => {
    // Some query parameters trigger page change.
    // We need to trigger scrolling for these parameters "manually"
    let shouldRestoreScrollPosition = false;
    const relevantSearchParams = createRelevantSearchParamsObj(location.search);

    if (!isEqual(relevantSearchParams, prevRelevantSearchParams)) {
      shouldRestoreScrollPosition = true;
    }
    
    setPrevRelevantSearchParams(relevantSearchParams);
    
    if (shouldRestoreScrollPosition) {
      scrollRestorationInProgress = true;
    }
  }, [location.search]);

  function createRelevantSearchParamsObj(urlSearchString) {
    const relevantSearchParams = {};

    for (let i = 0; i < queryParametersThatChangePage.length; i++) {

      const key = queryParametersThatChangePage[i];
      const relevantParam = getSearchParam(urlSearchString, key);

      if (relevantParam) {
        relevantSearchParams[key] = relevantParam;
      }
    }
    return relevantSearchParams;
  }

  // ---------------------------------------- //
  // Group of scroll restoration triggers END //
  // ---------------------------------------- //

  useEffect(() => {
    window.history.scrollRestoration = "manual";
    return () => {
      window.history.scrollRestoration = "auto";
    };
  }, []);

  function initiateScrollRestoration(urlHasHash) {
    // We won't try to restore any specific scroll position on the front page
    // because we set focus in the search input tag
    if (location.pathname === "/") {
      if (firstPageLoad && !urlHasHash) {
        // console.log("first page load, skipping scroll restoration");
        firstPageLoad = false;
        scrollRestorationInProgress = false;
        return;
      }
      window.scrollTo(0, 0);
      scrollRestorationInProgress = false;
      return;
    }
    const scrollYValue = scrollPositions[location.key];
    restoreScrollPosition(scrollYValue);
    scrollRestorationInProgress = false;
  }

  function saveCurrentScrollPosition() {
    let key;
    if (!window?.history?.state?.key) {
      key = "default";
    } else {
      key = window.history.state.key;
    }
    // console.log("saving scroll position:", window.scrollY, 
    // "to scrollPositions at key:", key,
    // "path:", window.location.href.replace("http://localhost:8080", ""));

    scrollPositions[key] = window.scrollY;
  }

  function addScrollEventListener() {
    window.addEventListener('scroll', (e) => {
      if (!scrollRestorationInProgress) {
        // performance.mark(`scroll event fired, saving pos, y: ${window.scrollY}, href: ${window.location.href}`);
        saveCurrentScrollPosition();
      } else {
        // performance.mark(`scroll event fired, SR in prog, y: ${window.scrollY}, href: ${window.location.href}`);
      }
    })
  }

  function restoreScrollPosition(scrollY) {
    let hash = trueHash;
    try {
      hash = decodeURI(hash);
    } catch (err) { }
    if (hash) {
      const scrollTarget = document.getElementById(hash.substring(1));

      if (scrollTarget) {
        // console.log("scrolling to hash inside SRS");
        window.scroll({ top: scrollTarget.offsetTop, behavior: "auto" });
        // console.log("setting firstPageLoad to false");
        firstPageLoad = false;
        return;
      }
    } else if (firstPageLoad) {
      // console.log("first page load, skipping scroll restoration");
      firstPageLoad = false;
      return;
    } else if (typeof scrollY === "number") {
      // console.log("scrolling to specific y:", scrollY);
      window.scrollTo(0, scrollY);
    } else {
      // console.log("scrolling to top");
      window.scrollTo(0, 0);
    }
    // performance.mark("bottom_of_actual_scroll_restoration");
  }

  return <></>;
  // return (
  //   <div style={{ padding: "25px", top: 60, position: "sticky", width: "30%" }}>
  //     <div>trueHash state: {`${trueHash}`}</div>
  //     <div>scrollTrigger state: {`${scrollTrigger}`}</div>
  //   </div>
  // );
}