const routes = {
  FRONT_PAGE: "/",
  CONTENT_PAGE: "/*",
  CATEGORY: "/b",
  SEARCH: "/s",
  PROFILE: "/profile",
  PROFILE_EDIT: "/profile/edit",
  PROFILE_EDIT_EDUCATION: "/profile/edit/education",
  PROFILE_AUTOLOGIN: "/profile/autologin",
  PERSONAL_HOME: "/home",
  SIGNUP: "/profile/signup",
  SIGNUP_PLAN: "/profile/signup/plan",
  LOGIN: "/profile/login",
  LOGOUT: "/profile/logout",
  LOGGED_OUT: "/logged-out",
  ACTIVITIES: "/activities",
  FAVORITES: "/favorites",
  APP_PAGE: "/app"
};

export default routes;