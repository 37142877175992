import React from 'react';
import SearchIcon from "../../components/icons/search";
import XIcon from "../../components/icons/x";
import IconHOC from "../../components/icons/hoc";
import ClassNames from '../../utils/classNames';

class SearchInput extends React.PureComponent {
  constructor(props) {
    super();
    this._onChange = onChange.bind(this);
    this.resetIconCreate = resetIconCreate.bind(this);
    this.searchIconCreate = searchIconCreate.bind(this);
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      if (this.props.disableAutoFocusOnMobile) {
        if (typeof window !== 'undefined') { // window is not present when rendering server side
          if (window.matchMedia('(min-width: 1240px)').matches) { // keep in sync with @container-medium in ~/CSS/variables.less!
            this.input.focus()
          }
        }
      }
      else {
        this.input.focus();
      }
    }
  }

  render() {
    const { placeholder, value, name, onFocus, onBlur } = this.props;
    const wrapperClasses = ClassNames({
      'search-input': true,
      'has-value': value,
    });
   const inputValue = value;
    return (
      <div className={wrapperClasses}>
        <div className="input">
          <input
            value={inputValue}
            type="search"
            name={name}
            placeholder={placeholder}
            onChange={this._onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={(input) => { this.input = input; }}
          />
        </div>
        {this.resetIconCreate()}
        {this.searchIconCreate()}
        <div className="underline" />
      </div>
    );
  }

  focus() {
    this.input.focus();
  }
}

function onChange(event) {
  this.props.onChange(event.target.value);
}

function resetIconCreate() {
  const { value, onChange } = this.props;

  if (!value)
    return null;

  const Icon = IconHOC(XIcon, "0 0 12.73 12.73");
  return (
    <div
      className="reset"
      onClick={onChange.bind(null, '')}
      onKeyDown={onChange.bind(null, '')}
      tabIndex="0"
    >
      <Icon height="9px" width="9px"/>
    </div>
  );
}

function searchIconCreate() {
  const { value, onSearchClick } = this.props;
  const searchIconIsClickable = !!onSearchClick;

  // if no search function is passed in, we hide search icon when value is present
  let searchIcon = null;
  const Icon = IconHOC(SearchIcon, "0 0 17.83 17.77");

  if (searchIconIsClickable) {
    searchIcon = (
      <div
        className="search clickable"
        onClick={onSearchClick.bind(null, value)}
        onKeyDown={onSearchClick.bind(null, value)}
        tabIndex="0"
      >
        <Icon className="icon-search" height="18px" width="18px" />
      </div>
    );
  } else if (!value) {
    searchIcon = <div className="search"><Icon className="icon-search" height="18px" width="18px"/></div>;
  }

  return searchIcon;
}

export default SearchInput;