import { useContext } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";
import { defaultOGImageUri } from "../../common/constants/uiConstants";
import ConfigContext from "../contexts/config";
import HeadTags from "../utils/headtag-helper";
import classNames from "../utils/classNames";
import MasterLayoutContext from "../contexts/masterLayout";

export default function MasterLayout() {
  const config = useContext(ConfigContext);
  const { fullWidth, hideFooter } = useContext(MasterLayoutContext);

  const classes = classNames({
    container: !fullWidth
  });

  return (
    <>
      <HeadTags>
        <title>{config.siteName}</title>
        <meta property="og:image" content={defaultOGImageUri} />
      </HeadTags>
      <header className={classes}>
        <Header container={!fullWidth} />
      </header>
      <main className={classes}>
        <Outlet />
      </main>
      {
        !hideFooter &&
        <div className={classes}>
          <Footer />
        </div>
      }
    </>
  );
}