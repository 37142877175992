
export const SEARCH_LOG = "searchlog";
export const SEARCH_RESULT_CLICK = "searchresultclick";
export const SEARCH_ALGORITHM = "learnerApp";
export const SEARCH_ALGORITHM_BOOK = "learnerApp-booksearch";
export const APP_INSTALLED = "appinstalled";
export const PRODUCT_ACCESSED = "productaccessed";
export const PRODUCT_ACCESSED_PAGE_READ = "productaccessedpageread";
export const MEGA_DROPDOWN_STATE_KEY = "learner-app-megadropdown-disabled";
export const PRODUCT_ACCESSED_SESSION_STORAGE_KEY = "productAccessedList";
export const GA4_QUERY_PARAMETER_KEY = "ga4";
export const CLIENT_LOGGING_RATE_LIMIT_DATE_KEY = "log_rate_limit_timestamp";
export const CLIENT_LOGGING_RATE_LIMIT_COUNT_KEY = "log_rate_limit_count";
export const TRACKING_DELAY = 5000;
export const FREE_WEBBOOK_READ_COUNT = "fwrc";
export const PODCAST_PLAYED = "podcastplayed";
export const PODCAST_STOPPED = "podcaststopped";