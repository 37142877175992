import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../styles/components/header/logo.module.scss";
import UserContext from "../../contexts/user";
import { useTranslation } from 'react-i18next';

export default function Logo() {
  const { user: contextUser } = useContext(UserContext);
  const [user, setUser] = useState(contextUser);
  useEffect(() => { setUser(contextUser) }, [contextUser]);

  const { t } = useTranslation();

  return (
    <div className={styles.headerLogo}>
      <Link to={user.authenticated ? "/home" : "/"} className={styles.logoLink} title={t("site.logoTitle")} >
        <img src={`/assets/logo.svg`} alt="logo" id="header-logo" />
      </Link>
    </div>
  );
}