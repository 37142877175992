import { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import staticRoutes from "../../../common/staticRoutes";
import SearchContext from '../../contexts/search';
import inputStyles from "../../styles/components/header/search-input.module.scss";
import styles from "../../styles/components/header/search.module.scss";
import classNames from '../../utils/classNames';
import IconHOC from '../icons/hoc';
import SearchIcon from "../icons/search";
import XIcon from '../icons/x';
import SearchInputOverlay from './search-input-overlay';

export default function HeaderSearch({ setActiveOverlay }) {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchButtonRef = useRef();

  const { setSearchValue, showSearchBox, setShowSearchBox, isActive, setIsActive, hasFocus, setHasFocus, closeSearchOverlayTrigger } = useContext(SearchContext);

  /**
   * @param {Event} e 
   */
  function clickHandler(e) {
    e?.preventDefault();
    if (showSearchBox) {
      onClose();
    } else {
      const element = document.querySelector("body");
      const viewWidth = element.clientWidth;
      const isMobile = viewWidth < Number.parseFloat(`${styles.containerMedium}`.replace("px", ""));
      setIsActive(!isActive);
      if (isMobile) {
        if (searchButtonRef.current?.scrollIntoViewIfNeeded) {
          window.requestAnimationFrame(() => {
            searchButtonRef.current.scrollIntoViewIfNeeded();
          })
        }
      }
    }
  }

  useEffect(() => {
    onClose();
  }, [closeSearchOverlayTrigger]);

  function onClose() {
    setActiveOverlay("");
    setIsActive(false);
    setShowSearchBox(false);
    setHasFocus(false);
  }

  function onSubmit(searchUrl, callback, value) {
    // Set initialQuery
    setSearchValue(value);
    // Hide suggested words
    // Callback used to hide suggested words
    callback();
    navigate(searchUrl);
  }

  const headerClasses = classNames({
    [styles.headerSearch]: true,
    [styles.open]: (isActive || showSearchBox),
    [styles.active]: !showSearchBox || hasFocus

  });

  const svgClasses = classNames({
    "active": (isActive || showSearchBox),
    "inactive": (!isActive && !showSearchBox)
  });

  const X = IconHOC(XIcon, "0 0 12.73 12.73");
  const SIcon = IconHOC(SearchIcon, "0 0 17.83 17.77");
  return (
    <>
      <div className={headerClasses}>
        <div ref={searchButtonRef} className={styles.iconWrapper} onClick={clickHandler}>
          <a href={staticRoutes.SEARCH} title={isActive ? t('header_search.close') : t('header_search.open')}>
            {
              (!isActive && !showSearchBox) &&
              <div className={inputStyles.headerSearchInput}>
                <SIcon className={svgClasses} />
              </div>
            }
            {
              (isActive || showSearchBox) &&
              <X className={svgClasses} />
            }
          </a>
        </div>
      </div>
      {
        (isActive) &&
        createPortal(
          <SearchInputOverlay onClose={onClose} onSubmit={onSubmit} />,
          document.querySelector("body")
        )
      }
    </>
  );
}