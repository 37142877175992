import ScrollRestorationStateKeeper from "./utils/scroll-restore-sev";
import App from "./app";

export default function AppWrapper() {
  return (
    <>
      <ScrollRestorationStateKeeper />
      <App
        menu={window.menu}
        user={window.user}
        config={window.config}
        pageData={window.pageData}
        requestLanguage={window.requestLanguage}
      />
    </>
  )
}