import { createContext } from "react";

const UserContext = createContext({
  user: {
    authenticated: false,
    educationName: '',
    isSubscriber: false,
    name: ''
  },
  setUser: () => {}
});
export default UserContext;