import { createContext } from "react";

const PageDataContext = createContext({
  data: {},
  setData: () => {},
  initialRender: false,
  setInitialRender: () => {},
  ssr: false,
  setSsr: () => {}
});
export default PageDataContext;