import React from 'react';
import RemoteQueryEngine from './remoteQueryEngine/index.js';

const HOC = (UI, limit, minQuery) => {
    class SearchProposals extends React.PureComponent {
    constructor() {
      super();
      this.state = {
        data: []
      };
      this.onQuery = onQuery.bind(this);
      this.minQuery = minQuery;
    }

    componentDidMount() {
      // setup query engine
      this.queryRemote = new RemoteQueryEngine()
          .setLimit(limit)
          .setRemote({
            url: '/api/search/suggestions?prefix=%QUERY&count=' + limit,
            wildcard: '%QUERY',
            transformer: (response) => {
              return response.json();
            }
          })
          .setResultHandler((data) => {
            this.setState({ data });
          })
          .init();
    }

    render() {
      const { data } = this.state;
      return (
        <UI
          {...this.props}
          asyncresults={data}
          onQuery={this.onQuery}
        />
      )
    }
  }

  return SearchProposals;
};


function onQuery(value) {
  if (value.length < this.minQuery)
    this.setState({ data: [] });

  if (value.length >= this.minQuery)
    this.queryRemote(value);
}

export default HOC;