import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ConfigContext from '../contexts/config';
import MasterLayoutContext from '../contexts/masterLayout';
import styles from "../styles/containers/error/main.module.scss";
import { getErrorMessages } from '../utils/error';
import HeadTags from '../utils/headtag-helper';
import { useScrollRestoringRenderWatcher } from '../utils/renderWatcher';

export default function Error({ code = 500, noFooter = false }) {
  const { t } = useTranslation();
  const { siteName } = useContext(ConfigContext);
  const { setHideFooter } = useContext(MasterLayoutContext);

  const { content, title } = getErrorMessages(t, code);
  useScrollRestoringRenderWatcher(content);

  useEffect(() => {
    setHideFooter(noFooter);

    return () => {
      setHideFooter(false);
    };

  }, [noFooter]);

  return (
    <>
      <HeadTags>
        <title>{`${title} - ${siteName}`}</title>
      </HeadTags>
      <div className={styles.errorPage}>
        <div className={styles.errorText}>
          <h1>{title}</h1>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </>
  )
}