// Wrapper for FontAwesome (FA) Icons using FA react component
// Props are passed directly through to the FA react component, for FA props see https://fontawesome.com/v6/docs/web/use-with/react/style
// Styling can be done either through the props mentioned above, or with CSS. CSS example can be found in the webbook toolbar module

// To add a new icon, find the desired icon on https://fontawesome.com/search?m=free&s=solid and add a wrapped export below
// NOTE: We can ONLY USE FREE ICONS, and primarily from the SOLID style, though there can be cases where it makes sense to use icons from other styles (ie. unfilled (from regular) and filled (from solid) heart for favourites)

import {
  faInfoCircle
  , faTrashCan
  , faSpinner
  , faList
  , faXmark
  , faArrowRight
  , faArrowLeft
  , faAngleUp
  , faAngleDown
  , faChevronRight
  , faChevronDown
  , faArrowDown
  , faHeart
  , faBookOpenReader
  , faMagnifyingGlassPlus
  , faMagnifyingGlassMinus
  , faDownload
} from "@fortawesome/free-solid-svg-icons";

import {
  faHeart as farHeart
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const withFontAwesomeIconWrapper = icon => (props) => {
  return <FontAwesomeIcon icon={icon} {...props} />
}

export const InfoIcon = withFontAwesomeIconWrapper(faInfoCircle);
export const TrashIcon = withFontAwesomeIconWrapper(faTrashCan);
export const SpinnerIcon = withFontAwesomeIconWrapper(faSpinner);
export const ListIcon = withFontAwesomeIconWrapper(faList);
export const XIcon = withFontAwesomeIconWrapper(faXmark);
export const ArrowRight = withFontAwesomeIconWrapper(faArrowRight);
export const ArrowLeft = withFontAwesomeIconWrapper(faArrowLeft);
export const ArrowDown = withFontAwesomeIconWrapper(faArrowDown);
export const AngleUp = withFontAwesomeIconWrapper(faAngleUp);
export const AngleDown = withFontAwesomeIconWrapper(faAngleDown);
export const ChevronRight = withFontAwesomeIconWrapper(faChevronRight);
export const ChevronDown = withFontAwesomeIconWrapper(faChevronDown);
export const Heart = withFontAwesomeIconWrapper(faHeart);
export const UnfilledHeart = withFontAwesomeIconWrapper(farHeart);
export const BookOpenReader = withFontAwesomeIconWrapper(faBookOpenReader);
export const MaginifyingGlassPlus = withFontAwesomeIconWrapper(faMagnifyingGlassPlus);
export const MaginifyingGlassMinus = withFontAwesomeIconWrapper(faMagnifyingGlassMinus);
export const Download = withFontAwesomeIconWrapper(faDownload);
