import { Suspense, lazy, startTransition, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReviewContext from "../../components/webbook/contexts/review-context";
import contentTypes from "../../content-types";
import PageDataContext from "../../contexts/pageData";
import SearchContext from "../../contexts/search";
import ContentFacade from "../../facades/content-facade";
import Error from "../error.jsx";
import Page from "./page";
import UserContext from "../../contexts/user";
import isEqual from "lodash.isequal";
import MenuContext from "../../contexts/menu";
import fetchUserMenu from "../../utils/fetchUserMenu";

const WebbookPage = lazy(() => import("./webbook"));
const Paper = lazy(() => import('./paper/paper.jsx'));
const PaperShow = lazy(() => import('./paper/paper-show.jsx'));

export default function Content() {
  const { data, setData, setInitialRender } = useContext(PageDataContext);
  const { setSearchValue } = useContext(SearchContext);
  const [content, setContentState] = useState(() => {
    return data || {};
  });
  const [reviews, setReviewsState] = useState(() => data.value?.reviews || {});
  const [userCanWriteReviews, setUserCanWriteReviewsState] = useState(data.bucket?.canReview);
  const [facade, setFacade] = useState(() => { return new ContentFacade() });
  const [shouldFetchContent, setShouldFetchContentState] = useState(true);

  const { pathname: path, search } = useLocation();
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);
  const { menu, setMenu } = useContext(MenuContext);

  const setShouldFetchContent = (input) => {
    startTransition(() => {
      setShouldFetchContentState(input);
    })
  }

  const setReviews = (input) => {
    startTransition(() => {
      setReviewsState(input);
    })
  }

  const setUserCanWriteReviews = (input) => {
    startTransition(() => {
      setUserCanWriteReviewsState(input);
    })
  }

  const setContent = (input) => {
    startTransition(() => {
      setContentState(input);
    })
  }

  useEffect(() => {
    // This is necessary to change search input value when user uses
    // <- and -> in the browser (native history navigation)
    if (content && content.bucket && content.bucket.query) {
      setSearchValue(content.bucket.query);
    }
  }, [content]);

  useEffect(() => {
    const html = document.querySelector("html");
    const lang = window.config?.language;
    if (content.type !== contentTypes.WEBBOOK && content.type !== contentTypes.PAPER) {
      html.lang = lang;
    } else if (content.value.metadata.language === lang) {
      html.lang = lang;
    } else {
      html.removeAttribute("lang");
    }
  }, [content]);

  async function claimsCallback(claims) {
    if (isEqual(user, claims))
      return;

    setUser(claims);
    const userMenu = await fetchUserMenu();
    menu.userMenu = userMenu;
    setMenu(menu);
  }

  async function getData(forcedRefresh) {
    function handleRedirect(response) {
      const { targetUrl } = response.value;
      // Absolute URIs cannot be handled by navigate
      // We use replace so we don't get an entry for the redirecting page in the browser history
      if (targetUrl.startsWith("http")) {
        window.location.replace(targetUrl);
      }
      else {
        navigate(targetUrl, { replace: true });
      }
    }

    try {
      if (forcedRefresh) {
        const res = await facade.getContent(path + search, claimsCallback);
        if (res.type === contentTypes.REDIRECT) {
          // redirect must happen from inside a useEffect(), otherwise we get "changing state while rendering" issues
          handleRedirect(res);
        } else {
          if (res.type === contentTypes.WEBBOOK
            || res.type === contentTypes.PAPER) {
            setReviews(res.value?.reviews);
            setUserCanWriteReviews(res.bucket?.canReview)
            delete res.value.reviews;
            delete res.bucket?.canReview;
          }
          setContent(res);
        }
        return;
      }
      if (shouldFetchContent) {
        const res = await facade.getContent(path + search, claimsCallback);
        if (res.type === contentTypes.REDIRECT) {
          // redirect must happen from inside a useEffect(), otherwise we get "changing state while rendering" issues
          handleRedirect(res);
        } else {
          if (res.type === contentTypes.WEBBOOK
            || res.type === contentTypes.PAPER) {
            setReviews(res.value?.reviews);
            setUserCanWriteReviews(res.bucket?.canReview)
            delete res.value.reviews;
            delete res.bucket?.canReview;
          }
          setContent(res);
          setInitialRender(true);
        }
      }
    } catch (err) {
      if (err.status) {
        setContent({ type: contentTypes.ERROR, statusCode: err.status });
      }
      // console.log("Failed to fetch content:", err);
    }
  }

  useEffect(() => {
    (async () => {
      if (!data || !data.type) {
        getData()
      }
    })();
  }, [path, search]);

  useEffect(() => {
    if (data) {
      setData();//set empty object
    }
  }, []);

  let component;

  switch (content.type) {
    case contentTypes.ERROR:
      component = <Error code={content.statusCode} />;
      break;
    case contentTypes.WEBBOOK:
      component = (
        <Suspense>
          <ReviewContext.Provider value={{
            reviews,
            setReviews,
            userCanWriteReviews,
            setUserCanWriteReviews
          }} >
            <main className="container">
              <WebbookPage
                setShouldFetchContent={setShouldFetchContent}
                refreshData={getData}
                data={content.value}
                isFavorite={content.bucket?.isFavorite}
                tocMatches={content.bucket?.tocMatches || []}
              />
            </main>
          </ReviewContext.Provider>
        </Suspense>
      );
      break;
    case contentTypes.REDIRECT:
      // The redirect itself is handled right after fetch to prevent double render issues
      component = <></>;
      break;
    case contentTypes.PAGE:
      component = (
        <Page
          value={content.value}
          bucket={content.bucket}
        />
      );
      break;
    case contentTypes.PAPER:
      component = (
        <Suspense>
          <ReviewContext.Provider value={{
            reviews,
            setReviews,
            userCanWriteReviews,
            setUserCanWriteReviews
          }} >
            <Paper {...content.value}
              breadcrumbs={content.value?.metadata?.breadcrumbs}
              title={content.value?.metadata?.title}
            />
          </ReviewContext.Provider>
        </Suspense>
      );
      break;
    case contentTypes.PAPERSHOW:
      component = (
        <Suspense>
          <PaperShow {...content.value}
            title={content.value?.metadata?.title}
            setShouldFetchContent={setShouldFetchContent}
            refreshData={getData}
          />
        </Suspense>
      );
      break;
    default:
      // This is the "loading" state/page
      component = <></>;
      break;
  }

  return (
    <>
      {component}
    </>
  )
}