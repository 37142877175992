export default function X({ color, classes }) {
  return (
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect x="5.36" y="-1.64" width="2" height="16" transform="translate(-2.64 6.36) rotate(-45)" fill={color || "#fff"} />
          <rect x="2.89" y="4.34" width="2" height="9" transform="translate(0.39 17.84) rotate(-135)" fill={color || "#fff"} />
          <rect x="8.9" y="-0.17" width="2" height="6" transform="translate(14.9 11.83) rotate(-135)" fill={color || "#fff"} />
        </g>
      </g>
  );
}