
/**
 * 
 * @param {Error} error 
 */
export default function reportAndThrowError(error) {
    if (typeof self.reportError === "function") {
        self.reportError(error);
    }
    throw error;
}