import { lazy, Suspense, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import templateTypes from "../../components/page/templateTypes";
import MenuContext from "../../contexts/menu";
import UserContext from '../../contexts/user';
import HeadTags from "../../utils/headtag-helper";

const Search = lazy(() => import('../../components/page/search'));
const PersonalHome = lazy(() => import('../../components/page/home'));
const FrontPage = lazy(() => import('../../components/page/frontpage'));
const Category = lazy(() => import('../../components/page/category'));
const InfoPage = lazy(() => import('../../components/page/info'));
const AuthorPage = lazy(() => import('../../components/page/author/author'));
const FaqPage = lazy(() => import('../../components/page/faq/faq'));
const FaqEntry = lazy(() => import('../../components/page/faq/faq-entry'));
const AuthorsPage = lazy(() => import('../../components/page/authors'));
const MarketingPage = lazy(() => import('../../components/page/marketing'));
const TextListPage = lazy(() => import('../../components/page/text-list'));
const Exam = lazy(() => import('../../components/page/exams/exams'));
const ExamEducations = lazy(() => import('../../components/page/exams/educations'));
const ExamAssignments = lazy(() => import('../../components/page/exams/assignments'));
const AppPage = lazy(() => import('../../components/page/app-page'));

export default function Page({ value, bucket }) {
  const { menu } = useContext(MenuContext);
  const { user } = useContext(UserContext);
  const location = useLocation();
  let templateComponent;
  let isFrontpage = false;

  const { template, metadata } = value;

  switch (template) {
    case templateTypes.FRONTPAGE:
      isFrontpage = true;
      templateComponent = <FrontPage {...value} />
      break;
    case templateTypes.CATEGORY:
      templateComponent = <Category
        {...value}
        items={bucket.pages}
        pageIndex={bucket.page}
        pageCount={bucket.pageCount}
      />
      break;
    case templateTypes.SEARCH:
      templateComponent = <Search
        {...value}
        {...bucket}
        location={location}
      />
      break;
    case templateTypes.INFO:
      templateComponent = <InfoPage {...value} menu={menu.infoMenu} />
      break;
    case templateTypes.HOME:
      if (!user.authenticated) {
        templateComponent = null;
        break;
      }
      templateComponent = <PersonalHome bucket={bucket} />
      break;
    case templateTypes.AUTHORS:
      templateComponent = <AuthorsPage {...value} />;
      break;
    case templateTypes.AUTHOR:
      templateComponent = <AuthorPage {...value} items={bucket.products} />;
      break;
    case templateTypes.FAQ:
      templateComponent =
        <FaqPage {...value}
          menu={menu.infoMenu}
          breadcrumbs={value?.metadata?.breadcrumbs}
          categories={value?.faqCategories}
        />;
      break;
    case templateTypes.FAQ_CATEGORY:
      templateComponent =
        <FaqPage {...value}
          menu={menu.infoMenu}
          breadcrumbs={value?.metadata?.breadcrumbs}
          entries={value?.faqEntries}
        />;
      break;
    case templateTypes.FAQ_ENTRY:
      templateComponent =
        <FaqEntry {...value}
          menu={menu.infoMenu}
          breadcrumbs={value?.metadata?.breadcrumbs}
        />;
      break;
    case templateTypes.MARKETING:
      templateComponent =
        <MarketingPage {...value}
        />;
      break;
    case templateTypes.TEXTLIST:
      templateComponent =
        <TextListPage {...value}
        />;
      break;
    case templateTypes.EXAM:
      templateComponent =
        <Exam {...value}
        />;
      break;
    case templateTypes.EXAM_EDUCATIONS:
      templateComponent =
        <ExamEducations {...value}
        />;
      break;
    case templateTypes.EXAM_ASSIGNMENTS:
      templateComponent =
        <ExamAssignments {...value} {...bucket}
        />;
      break;
    case templateTypes.APP_DOWNLOAD:
      templateComponent =
        <AppPage
          {...value}
          title={value.metadata?.title}
          {...bucket}
        />;
      break;
    default:
      templateComponent = <>No Template</>
  }

  return (
    <>
      <HeadTags>
        <title>{metadata.metaTitle}</title>
        <meta name="description" content={metadata.metaDescription} />
        {!!metadata.nextPage && <link rel="next" href={metadata.nextPage.uri} />}
        {!!metadata.previousPage && <link rel="prev" href={metadata.previousPage.uri} />}
        {!!metadata.uri && <link rel="canonical" href={metadata.uri} />}
        {!!metadata.noIndex && <meta name="robots" content="noindex, follow" />}
        {!!metadata.structuredData?.breadcrumbs &&
          <script type="application/ld+json">
            {metadata.structuredData.breadcrumbs}
          </script>
        }
        {!!metadata.structuredData?.page &&
          <script type="application/ld+json">
            {metadata.structuredData.page}
          </script>
        }
      </HeadTags>
      <Suspense>
        {templateComponent}
      </Suspense>
    </>
  );
}