import { useContext } from 'react';
import { Link } from 'react-router-dom';
import MenuContext from '../../contexts/menu';
import styles from '../../styles/components/header/menu.module.scss';
import { isExternalLink } from '../../../common/utils/url';
import DropDown from './user-menu-dropdown';

export default function UserMenuDesktop() {
  const {menu} = useContext(MenuContext);
  const userMenu = menu.userMenu;
  if (!userMenu || userMenu.length === 0) return <></>;
  const lis = userMenu
    .map((item, i) => {
      const {uri, tooltip, title, id} = item;

      const isExternal = isExternalLink(item.uri);
      const link = isExternal 
        ? <a href={uri} title={tooltip} id={id}>{title}</a>
        : <Link to={item.uri} title={tooltip} id={id}>{item.title}</Link>;
      const hasChildren = !!item.children;
      return (
        <li key={i}>
          {
            hasChildren
              ? <DropDown item={item} />
              : link
          }
        </li>
      );
    })
    // use a reduce function to create nested array, this enables us to inject <li>/</li>. array.join no good
    .reduce((prev, curr, i) => [prev, <li key={`${i}${userMenu[i].uri}`}>/</li>, curr]);

  return (
    <div id="user-menu"
      className={styles.user}
    >
      <nav>
        <ul>
          {lis}
        </ul>
      </nav>
    </div>
  );
}
