import { useEffect, useState } from "react";
import styles from '../styles/utils/mediaSize.module.scss';

export const breakpoints = {
  MOBILE: "mobile",
  DESKTOP: "desktop",
  DESKTOP_LARGE: "desktop-large"
}

/**
 * Hook for matching vieport breakpoints.
 * Only works client side, returns `null` when used server side, as there is no way of detecting window size on the server
 * @returns {"mobile"|"desktop"|"desktop-large"|null}
 */
export default function useMediaSize() {
  const [mediaSize, setMediaSize] = useState(null);

  const eventListeners = {};

  useEffect(() => {
    /**
     * 
     * @param {String} media 
     * @param {MediaQueryListEvent} event
     */
    function mediaQueryEventHandler(media, event) {
      if (event.matches)
        setMediaSize(media);
    }
    
    try {
      /**
       * @typedef {{query:MediaQueryList, media:String}} mediaQueryListItem
       * @type {Array<mediaQueryListItem>}
       */
      const queryList = [
        { query: window.matchMedia(`(max-width: ${styles.containerMedium})`), media: breakpoints.MOBILE },
        { query: window.matchMedia(`(min-width: ${styles.containerMedium}) and (max-width: ${styles.containerLarge})`), media: breakpoints.DESKTOP },
        { query: window.matchMedia(`(min-width: ${styles.containerLarge})`), media: breakpoints.DESKTOP_LARGE }
      ];
  
      queryList.forEach((queryObj) => {
        if (queryObj.query.matches) {
          setMediaSize(queryObj.media);
        }
  
        eventListeners[queryObj.media] = mediaQueryEventHandler.bind(null, queryObj.media);
        queryObj.query.addEventListener("change", eventListeners[queryObj.media]);
      });
    } catch (err) {
      setMediaSize(null);
    }

    return () => {
      try {
        queryList.forEach((queryObj) => {
          queryObj.query.removeEventListener("change", eventListeners[queryObj.media]);
        });
      } catch (err) {
      }
    };
  })

  return mediaSize;
}