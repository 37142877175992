const ignoredPathPrefixes = [
  "api/",
  "assets/",
  "cms/",
  "media/",
  "out/",
  "in/",
  "Opgaver/",
  "Uppsatser/",
  "documente/",
  "Oppgaver/",
  "Schularbeiten/",
  "profile", // No trailing slash on profile, as we DO link to it without
  "favicon.ico"
]

// Cannot be default export because of Jest limitations
export { ignoredPathPrefixes };